import { QueryKey } from '../mongo/localdate_analysis_data'
import {
  CorrelationConfig,
  CorrelationRequestVariablesId,
  HRVConfig,
  SleepScoreConfig,
  SleepWakeConfig,
} from './request'

export interface AnalysisServiceResponse<T = unknown> {
  metadata: T
  [key: string]: unknown
}

export enum CorrelationResultAnalysisType {
  Correlation = 'correlation',
  RepeatedMeasuresCorrelation = 'repeated_measures_correlation',
  Distribution = 'distribution',
  PairedSampleTTest = 'paired_sample_t_test',
  TwoSampleTTest = 'two_sample_t_test',
  TwoWayAnova = 'two_way_anova',
  PairwiseTukeyHSD = 'pairwise_tukeyhsd',
}

export interface CorrelationResultCoordinate {
  row: string
  column: string
}

export interface CorrelationResultData {
  coord: CorrelationRequestVariablesId
  analysis_type: CorrelationResultAnalysisType
}

export interface CorrelationResultCorrelationData
  extends CorrelationResultData {
  analysis_type: CorrelationResultAnalysisType.Correlation
  r_value: number | null
  p_value: number | null
  r_squared: number | null
}

export interface CorrelationResultRepeatedMeasuresCorrelationData
  extends CorrelationResultData,
    Pick<
      CorrelationResultCorrelationData,
      'r_value' | 'p_value' | 'r_squared'
    > {
  analysis_type: CorrelationResultAnalysisType.RepeatedMeasuresCorrelation
  ci_95_low: number | null
  ci_95_high: number | null
  power: number | null
}

export interface CorrelationResultDistributionData
  extends CorrelationResultData {
  analysis_type: CorrelationResultAnalysisType.Distribution
  length: number
  valid_count: number
  mean: number | null
  std: number | null
  sem: number | null
  min: number | null
  p25: number | null
  p50: number | null
  p75: number | null
  max: number | null
  ci_95: number | null
}

export interface CorrelationResultTTestData extends CorrelationResultData {
  analysis_type:
    | CorrelationResultAnalysisType.PairedSampleTTest
    | CorrelationResultAnalysisType.TwoSampleTTest
  distributions: [
    CorrelationResultDistributionData,
    CorrelationResultDistributionData,
  ]
  distributions_diff: CorrelationResultDistributionData
  t_value: number | null
  p_value: number | null
  bh_adjusted_p_value: number | null
  ci_95_low: number | null
  ci_95_high: number | null
}

export interface CorrelationResultTwoWayAnovaDataItem {
  key: string | string[]
  sum_sq: number | null
  df: number | null
  f_value: number | null
  p_value: number | null
}

export interface CorrelationResultTwoWayAnovaData
  extends CorrelationResultData {
  analysis_type: CorrelationResultAnalysisType.TwoWayAnova
  items: CorrelationResultTwoWayAnovaDataItem[]
}

export interface CorrelationResultPairwiseTukeyHSDDataPair {
  coord_1: CorrelationRequestVariablesId
  coord_2: CorrelationRequestVariablesId
  mean_diff: number | null
  p_value: number | null
  ci_95_low: number | null
  ci_95_high: number | null
  reject: boolean
}

export interface CorrelationResultPairwiseTukeyHSDData
  extends CorrelationResultData {
  analysis_type: CorrelationResultAnalysisType.PairwiseTukeyHSD
  df: number | null
  variance: number | null
  q_crit: number | null
  pairs: CorrelationResultPairwiseTukeyHSDDataPair[]
  distributions: CorrelationResultDistributionData[]
}

export interface CorrelationResultGraph {
  analysis_type: CorrelationResultAnalysisType
  graph: string | null
}

export interface CorrelationResultItem {
  coord: CorrelationResultCoordinate
  data: CorrelationResultData[]
  graph: CorrelationResultGraph
  sub_graphs?: CorrelationResultGraph[]
}

export interface CorrelationResult {
  items: CorrelationResultItem[][]
}

export interface CorrelationResponse extends AnalysisServiceResponse {
  result: CorrelationResult
  config: CorrelationConfig
}

export interface SleepResponse extends AnalysisServiceResponse<QueryKey> {
  sleep_score: SleepScoreResult
  sleep_wake?: SleepWakeResult
}

export interface SleepScoreResult {
  sleep_score: SleepScoreData[]
  config: SleepScoreConfig
}

export interface SleepScoreData {
  t: number
  sc: number | null
}

export interface SleepWakeResult {
  sleep_wake: SleepWakeData[]
  config: SleepWakeConfig
}

export interface SleepWakeData {
  t: number
  sw: boolean
}

export interface HRVResponse extends AnalysisServiceResponse<QueryKey> {
  hrv: HRVResult
}

export interface HRVResult {
  hrv: HRVData[]
  config: HRVConfig
}

export interface HRVData {
  t: number
  count: number
  hrv?: HRVDataItem
}

export interface HRVDataItem {
  mrr: number | null
  sdrr: number | null
  rmssd: number | null
  prr50: number | null
  tp: number | null
  vlf: number | null
  lf: number | null
  hf: number | null
  lfhf: number | null
}
