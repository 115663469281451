import { Dispatch, SetStateAction } from 'react'
import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
  RequestResult,
} from '../../lib'
import {FetchDurations} from '../../model/dto'
import {CorrelationConfig, Variable} from '../../shared/analysis'

import {doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_MULTIPLE_DURATION_FETCH =
  'REQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_MULTIPLE_DURATION_FETCH'
export const doREQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_MULTIPLE_DURATION_FETCH = createDoRequestAction(
  REQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_MULTIPLE_DURATION_FETCH,
)

export const requestAnalyticGraphCorrelationIndividualMultipleDurationFetchActionCreators = {
  doREQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_MULTIPLE_DURATION_FETCH,
}

export function* requestAnalyticGraphCorrelationIndividualMultipleDurationFetch({
  payload,
  type,
  setRequestResult,
}: {
  payload: {
    requestId: string
    participantId: string
    yymmddDurations: FetchDurations
    variables: Variable[]
    config?: CorrelationConfig
  }
  type: string
  setRequestResult: Dispatch<SetStateAction<RequestResult>>
}): SagaIterator {
  debug('saga*requestAnalyticGraphCorrelationIndividualMultipleDurationFetch')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        participantId: v.string().uuid().exist(),
        yymmddDurations: v
          .object<FetchDurations>()
          .pattern(
            v.string(),
            v.object({
              yymmddIndexStart: v.number().integer().positive().required(),
              yymmddIndexEnd: v.number().integer().positive().required(),
            }),
          )
          .min(2)
          .required(),
        variables: v
          .array()
          .min(2)
          .max(4)
          .items(
            v
              .object({
                identifier: v.string().exist(),
              })
              .unknown(true)
              .required(),
          )
          .required(),
        config: v
          .object({
            color_discreate_sequence: v.array().items(v.string()).optional(),
          })
          .optional(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_MULTIPLE_DURATION_FETCH,
        requestId: payload.requestId,
        error,
      }, setRequestResult),
    )
  }

  const {participantId, yymmddDurations, variables, config} = payload
  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/analytic-graph-correlation-individual-multiple-duration-fetch`,
    accessToken,
    data: {
      participantId,
      yymmddDurations,
      variables,
      config,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)

    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_MULTIPLE_DURATION_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/analytic-graph-correlation-individual-multiple-duration-fetch',
        ...result,
        error,
      }, setRequestResult),
    )
  }

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_MULTIPLE_DURATION_FETCH,
      fromPayload: payload,
      requestId: payload.requestId,
      ...result,
    }, setRequestResult),
  )
}

export function* analyticGraphCorrelationIndividualMultipleDurationFetchSaga() {
  yield takeEvery(
    REQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_MULTIPLE_DURATION_FETCH,
    requestAnalyticGraphCorrelationIndividualMultipleDurationFetch,
  )
}
