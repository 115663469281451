import {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

import {v, RIF, debug, useForm, useCurrentWorkspaceState, RequestResult} from '../../lib'

import {createDispatchActions, selectWorkspaces} from '../../store'

import {PopupSimple, Input, LabelBox} from '..'

const schema = v.object({
  name: v.string().max(50).required(),
})

export const ProjectCreatePop = (props: any) => {
  const {setDisplayCreatePopup, setDisplayEditPopup, isEditPopup, projectName, editProjectId, ...rest} = props

  const {doREQUEST_PROJECT_CREATE, doREQUEST_PROJECT_UPDATE}: any = createDispatchActions()
  const workspacesState = selectWorkspaces()
  const {workspaceId} = useCurrentWorkspaceState()
  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)
  const projectId = isEditPopup ? editProjectId : requestResult?.result?.payload?.batchList?.[0]?.projectId

  const navigate = useNavigate()

  const [value, setValue] = useState('')
  const [editValue, setEditValue] = useState(projectName || '')

  const {register, handleSubmit} = useForm({schema})

  const handleChange = (value: any) => {
    setValue(value)
  }

  const handleEditChange = (value: any) => {
    setEditValue(value)
  }

  const onSubmit = (data: any) => {
    debug('ProjectCreate&EditForm.onSubmit', {data})
    if (!isEditPopup) {
      doREQUEST_PROJECT_CREATE({
        setRequestResult,
        payload: {
          workspaceId,
          ...data,
        },
      })
    }
    if (isEditPopup) {
      doREQUEST_PROJECT_UPDATE({
        setRequestResult,
        payload: {
          projectId,
          ...data,
        },
      })
    }
  }

  const onError = (error: any) => {
    debug('ProjectCreateForm.onError', {error})
  }

  useEffect(() => {
    if (!requestResult) return
    if (!requestResult?.success) return
    if (isEditPopup) return setDisplayEditPopup(false)
    if (!isEditPopup) return navigate(`/project_setup/${projectId}`)
  }, [requestResult])

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      data-testid='create_project_pop'
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        top: 0,
        zIndex: '103',
      }}
    >
      {RIF(
        !isEditPopup,
        <PopupSimple
          {...rest}
          method='Create'
          name='New Project'
          description='Get started on creating a new project by giving it a name. '
          buttonColor='primary'
          buttonText='Create Project'
          closeAction={setDisplayCreatePopup}
          btnDisableAction={value.length <= 0}
          html={
            <LabelBox
              {...{
                label: 'Project Name',
                leftChracters: 50 - value.length,
              }}
            >
              <Input
                {...register('name')}
                placeholder='Write a short and easy to identify name for your project'
                value={value}
                maxLength='50'
                onChange={(e: any) => handleChange(e.target.value)}
                css={{marginBottom: '50px'}}
              />
            </LabelBox>
          }
        ></PopupSimple>,
      )}

      {RIF(
        isEditPopup,
        <PopupSimple
          {...rest}
          method='Edit'
          name='Project Name'
          description="The following information will be seen by you and your team member(s). It won't be seen by participants."
          buttonColor='primary'
          buttonText='Save'
          closeAction={setDisplayEditPopup}
          html={
            <LabelBox
              {...{
                label: 'Project Name',
                leftChracters: 50 - editValue?.length,
              }}
            >
              <Input
                {...register('name')}
                placeholder='Write a short and easy to identify name for your project'
                value={editValue}
                maxLength='50'
                onChange={(e: any) => handleEditChange(e.target.value)}
                css={{marginBottom: '50px'}}
              />
            </LabelBox>
          }
        ></PopupSimple>,
      )}
    </form>
  )
}
