import {useState, SetStateAction, Dispatch, useMemo, useEffect} from 'react'
import {selectTheme, selectCorrelationVariable} from '../../store'
import {Button, ButtonCancel, Select} from '..'
import DatePicker, {DateObject} from 'react-multi-date-picker'
import {darken, _, t, getVariableDisplayName, getVariableCode} from '../../lib'
import {ParticipantItem, DataTypeDisplayNameMap} from '../../model'
import {useParams} from 'react-router-dom'
import {Scrollbars} from 'react-custom-scrollbars-2'
import {AddGreyIcon, AddBlackIcon} from '../../asset/image'
import {v4} from 'uuid'
import {RIF} from '../../lib'
import {Variable} from '../../shared/analysis'
import {
  AnalysisSidebarSubject,
  AnalysisSidebarSubjectType,
  DataSetSelectionItem,
  GROUP_MINIMUM_SIZE,
} from '../../model/analysis'
import {AnalysisDataResolverArgs} from '../../lib/chart_data/data_loader/analysis_data_resolver'

interface AnalysisSideBarProps {
  participantList: ParticipantItem[]
  onParticipantSelect: (participant: ParticipantItem) => void
  setDuration: Dispatch<SetStateAction<[DateObject, DateObject]>>
  duration: [DateObject, DateObject]
  currentParticipantId: string
  resolution: string
  setResolution: Dispatch<SetStateAction<string>>
  displayingVariableList: Variable[]
  setDisplayingVariableList: Dispatch<SetStateAction<Variable[]>>
  displayingDataSetSelectionList: DataSetSelectionItem[]
  setDisplayingDataSetSelectionList: Dispatch<SetStateAction<DataSetSelectionItem[]>>
  onUpdateAnalysisArgs: (args: AnalysisDataResolverArgs) => void
}

interface ParticipantGroup {
  participantId: string
  participantInsignia: string
  groupName: string
}

enum DataSetSelectionActionType {
  ADD,
  DELETE,
  UPDATE,
}

type DataSetSelectionAction =
  | {
      type: DataSetSelectionActionType.ADD
    }
  | {
      type: DataSetSelectionActionType.DELETE
      payload: string
    }
  | {
      type: DataSetSelectionActionType.UPDATE
      payload: {
        key: string
        value: DataSetSelectionItem
      }
    }

export const AnalysisSideBar = (props: AnalysisSideBarProps) => {
  const {color, fontSize} = selectTheme()
  const {
    participantList,
    displayingVariableList,
    setDisplayingVariableList,
    displayingDataSetSelectionList,
    setDisplayingDataSetSelectionList,
    onUpdateAnalysisArgs,
  } = props

  const [subjectList, setSubjectList] = useState<AnalysisSidebarSubject[]>([])
  useMemo(() => {
    const result = generateSubjectList(participantList)
    if (!_.isEqual(result, subjectList)) setSubjectList(result)
  }, [participantList])

  const projectId = useParams().projectId ?? ''
  const correlationVariableList: Variable[] = selectCorrelationVariable(projectId)
  const [dataSetSelectionList, setDataSetSelectionList] = useState<DataSetSelectionItem[]>(
    subjectList.length
      ? [
          {
            key: v4(),
            subject: subjectList[0],
            duration: [new DateObject().subtract(7, 'days'), new DateObject()],
          },
        ]
      : [],
  )

  const [dataSetSubjectList, setDataSetSubjectList] = useState<AnalysisSidebarSubject[]>([])
  useMemo(() => {
    if (subjectList.length) setDataSetSubjectList(subjectList)
  }, [subjectList])

  const [variableList, setVariableList] = useState<Variable[]>(displayingVariableList)
  const [resolutionValue, setResolutionValue] = useState<Record<string, string>>({value: 'daily', label: 'Daily'})
  const resolutionOptions = [
    {value: 'daily', label: 'Daily'},
    {value: 'weekly', label: 'Weekly'},
    {value: 'monthly', label: 'Monthly'},
  ]

  const updateSettingsButtonDisabled = () => {
    if (!dataSetSelectionList.length) return true
    if (
      _.isEqual(displayingVariableList, variableList) &&
      _.isEqual(displayingDataSetSelectionList, dataSetSelectionList)
    ) {
      return displayingDataSetSelectionList.every((dataSetSelection, i) =>
        dataSetSelection.duration.every((date, j) => date.toUnix() === dataSetSelectionList[i].duration[j].toUnix()),
      )
    }
  }

  const onDataSetSelectionAction = (dataSetSelectionList: DataSetSelectionItem[], action: DataSetSelectionAction) => {
    let newDataSetSelectionList: DataSetSelectionItem[] = [...dataSetSelectionList]

    if (!newDataSetSelectionList.length) {
      if (action.type === DataSetSelectionActionType.ADD) {
        if (dataSetSubjectList.length) {
          const subject = dataSetSubjectList[0]
          setDataSetSelectionList([
            {
              key: v4(),
              subject,
              duration: [new DateObject().subtract(7, 'days'), new DateObject()],
            },
          ])
        }
      }
      return
    }

    const anchorSubject = newDataSetSelectionList[0].subject

    switch (action.type) {
      case DataSetSelectionActionType.ADD: {
        const selectedSubjects = newDataSetSelectionList.map((dataSetSelection) => dataSetSelection.subject)
        const selectedDurations = newDataSetSelectionList.map((dataSetSelection) => dataSetSelection.duration)

        if (newDataSetSelectionList.length === 1) {
          const subject =
            dataSetSubjectList.find(
              (subject) => subject.type === anchorSubject.type && subject.name !== anchorSubject.name,
            ) ?? anchorSubject
          newDataSetSelectionList.push({
            key: v4(),
            subject,
            duration: selectedDurations[0],
          })
          setDataSetSubjectList(subjectList.filter((subject) => subject.type === anchorSubject.type))
        } else {
          const incompleteSubject =
            _.head(findIncomplete(selectedSubjects, JSON.stringify)) ?? t.unwrap(_.sample(selectedSubjects))
          const incompleteDuration =
            _.head(
              findIncomplete(selectedDurations, ([d1, d2]) =>
                JSON.stringify([d1.format('YYYY-MM-DD'), d2.format('YYYY-MM-DD')]),
              ),
            ) ?? t.unwrap(_.sample(selectedDurations))
          newDataSetSelectionList.push({
            key: v4(),
            subject: incompleteSubject,
            duration: incompleteDuration,
          })
        }
        break
      }
      case DataSetSelectionActionType.DELETE: {
        newDataSetSelectionList = newDataSetSelectionList.filter(
          (dataSetSelection) => dataSetSelection.key !== action.payload,
        )
        break
      }
      case DataSetSelectionActionType.UPDATE: {
        const {key, value} = action.payload
        newDataSetSelectionList = newDataSetSelectionList.map((dataSetSelection) =>
          dataSetSelection.key === key ? value : dataSetSelection,
        )
        break
      }
    }

    // Reset data set subject list to all inclusive
    if (newDataSetSelectionList.length === 1) {
      setDataSetSubjectList(subjectList)
    }
    setDataSetSelectionList(newDataSetSelectionList)
  }

  const handleUpdateSettings = () => {
    setDisplayingVariableList(variableList)
    setDisplayingDataSetSelectionList(dataSetSelectionList)
    const subjects = _.uniqBy(
      dataSetSelectionList.map((dataSetSelection) => dataSetSelection.subject),
      'name',
    )
    const durations = _.uniqBy(
      dataSetSelectionList.map((dataSetSelection) => dataSetSelection.duration),
      (duration) => `${duration[0].format('YYYY-MM-DD')}-${duration[1].format('YYYY-MM-DD')}`,
    )
    const analysisDataResolverArgs = {
      subjects,
      durations,
      variables: variableList.map((variable, index) => ({
        ...variable,
        identifier: `variable${getVariableCode(index)}`,
      })),
    }

    onUpdateAnalysisArgs(analysisDataResolverArgs)
  }

  const handleChangeResolution = (e: Record<string, string>) => {
    setResolutionValue(e)
  }

  const addVariableBlock = () => {
    setVariableList([...variableList, _.sample(correlationVariableList) as Variable])
  }

  useEffect(() => {
    if (!subjectList.length) return
    setDataSetSelectionList([
      {
        key: v4(),
        subject: subjectList[0],
        duration: [new DateObject().subtract(7, 'days'), new DateObject()],
      },
    ])
  }, [subjectList])

  useMemo(() => {
    if (displayingVariableList.length === 0) return
    setVariableList(displayingVariableList)
  }, [displayingVariableList])

  const handleAddDataSetSelection = () => {
    onDataSetSelectionAction(dataSetSelectionList, {type: DataSetSelectionActionType.ADD})
  }

  return (
    <div
      css={{
        width: '256px',
        height: 'calc(100vh - 56px)',
        backgroundColor: color.white,
        padding: '16px',
        borderRight: `1px solid ${color.borderLight}`,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Scrollbars css={{flex: 1}}>
        {/* data set section */}
        <div
          css={{
            paddingBottom: '24px',
            marginBottom: '24px',
            borderBottom: `1px solid ${color.border._80}`,
          }}
        >
          <div
            css={{
              display: 'flex',
              marginBottom: '8px',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p
              css={{
                fontSize: fontSize.h7,
                color: color.textIcon.secondary,
                marginRight: '4px',
              }}
            >
              Data Set
            </p>

            {/* <Tooltip content={''}/> */}
          </div>
          {dataSetSelectionList.map((dataSetSelection, index) => (
            <DataSetSelection
              {...{
                key: dataSetSelection.key,
                dataSetSelectionKey: dataSetSelection.key,
                index,
                selected: dataSetSelection,
                subjectList: dataSetSubjectList,
                showCloseButton: dataSetSelectionList.length > 1,
                showCompareWithAnotherButton: dataSetSelectionList.length < 4,
                onUpdate: (key, value) => {
                  setDataSetSelectionList((prev) => {
                    const newDataSetSelectionList = [...prev]
                    const index = newDataSetSelectionList.findIndex((item) => item.key === key)
                    newDataSetSelectionList.splice(index, 1, value)
                    return newDataSetSelectionList
                  })
                },
                onDelete: (key) => {
                  setDataSetSelectionList((prev) => {
                    const newDataSetSelectionList = [...prev]
                    const index = newDataSetSelectionList.findIndex((item) => item.key === key)
                    newDataSetSelectionList.splice(index, 1)
                    return newDataSetSelectionList
                  })
                },
              }}
            />
          ))}
          <button
            onClick={handleAddDataSetSelection}
            css={{
              width: '100%',
              borderRadius: '3px',
              border: 'none',
              backgroundColor: color.surface.grey.background,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: color.textIcon.secondary,
              cursor: 'pointer',
              fontSize: fontSize.h8,
              padding: '4px',
            }}
          >
            Compare with another data set
          </button>
        </div>
        {/* resolution section */}
        <div
          css={{
            paddingBottom: '24px',
            marginBottom: '24px',
            borderBottom: `1px solid ${color.border._80}`,
          }}
        >
          <div css={{display: 'flex', marginBottom: '8px'}}>
            <p
              css={{
                fontSize: fontSize.h7,
                color: color.textIcon.secondary,
                marginRight: '4px',
              }}
            >
              Resolution
            </p>
            {/* <Tooltip content={''}/> */}
          </div>
          <Select value={resolutionValue} options={resolutionOptions} onChange={handleChangeResolution} />
        </div>

        {/* variable section */}
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            marginBottom: '24px',
          }}
        >
          <div
            css={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '8px',
            }}
          >
            <div css={{display: 'flex'}}>
              <p
                css={{
                  fontSize: fontSize.h7,
                  color: color.textIcon.secondary,
                  marginRight: '4px',
                }}
              >
                Variables
              </p>
              {/* <Tooltip content={''}/> */}
            </div>
            <ButtonAddVariable
              {...{
                disabled: variableList.length === 4,
                addVariableBlock,
                setVariableList,
              }}
            />
          </div>
          {variableList.map((variable, index) => (
            <VariableBlock
              {...{
                key: v4(),
                variable,
                variableList,
                setVariableList,
                index,
              }}
            />
          ))}
        </div>
        <Button
          css={{
            margin: '0 auto',
          }}
          disabled={updateSettingsButtonDisabled()}
          onClick={handleUpdateSettings}
        >
          Update Settings
        </Button>
      </Scrollbars>
    </div>
  )
}

interface VariableItemProps {
  variable: Variable
  variableList: Variable[]
  setVariableList: Dispatch<SetStateAction<Variable[]>>
  index: number
}

const VariableBlock = (props: VariableItemProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {variable, variableList, setVariableList, index} = props

  const projectId = useParams().projectId ?? ''
  const correlationVariableList = selectCorrelationVariable(projectId) || []

  const [dataTypeOptions, setDataTypeOptions] = useState<Record<string, string>[]>([])
  const [dataTypeValue, setDataTypeValue] = useState<Record<string, string>>({
    value: variable.dataType,
    label: DataTypeDisplayNameMap[variable.dataType],
  })

  type VariableTypeItem = Variable & {
    value: string
    label: string
  }
  const [variableTypeOptions, setVariableTypeOptions] = useState<Record<string, VariableTypeItem[]>>({})
  const [variableTypeValue, setVariableTypeValue] = useState<VariableTypeItem>({
    value: variable.variableType,
    label: getVariableDisplayName(variable),
    ...variable,
  })

  const handleClose = () => {
    setVariableList((prev) => {
      const newVariableList = [...prev]
      newVariableList.splice(index, 1)
      return newVariableList
    })
  }

  useMemo(() => {
    if (correlationVariableList.length === 0) return
    const tempDataTypeOptions: Record<string, string>[] = []
    const tempVariableTypeOptions: Record<string, VariableTypeItem[]> = {}
    correlationVariableList.forEach((variable: Variable) => {
      tempDataTypeOptions.push({
        value: variable.dataType,
        label: DataTypeDisplayNameMap[variable.dataType] || variable.dataType,
      })
      if (!tempVariableTypeOptions[variable.dataType]) tempVariableTypeOptions[variable.dataType] = []
      tempVariableTypeOptions[variable.dataType].push({
        ...variable,
        value: variable.variableType,
        label: getVariableDisplayName(variable),
      })
    })
    setDataTypeOptions(_.uniq(_.uniqBy(tempDataTypeOptions, 'value')))
    setVariableTypeOptions(tempVariableTypeOptions)
  }, [correlationVariableList])

  useMemo(() => {
    if (!Object.keys(variableTypeOptions).length) return
    setVariableTypeValue(variableTypeOptions[dataTypeValue.value][0])
  }, [dataTypeValue])

  useMemo(() => {
    if (!Object.keys(variableTypeOptions).length) return
    setVariableList((prev) => {
      const newVariableList = [...prev]
      newVariableList.splice(index, 1, _.omit(variableTypeValue, ['value', 'label']) as Variable)
      return newVariableList
    })
  }, [variableTypeValue])

  return (
    <div
      css={{
        width: '100%',
        padding: '12px',
        borderRadius: '5px',
        border: `1px solid ${color.border._80}`,
        backgroundColor: color.surface.grey.light,
        marginBottom: '8px',
      }}
    >
      <div
        css={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '8px',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <p
            css={{
              width: '12px',
              height: '12px',
              lineHeight: '12px',
              borderRadius: '50%',
              fontSize: fontSize.h8,
              fontWeight: fontWeight.thick,
              color: color.white,
              backgroundColor: color.surface.dark,
              textAlign: 'center',
              marginRight: '4px',
            }}
          >
            {getVariableCode(index)}
          </p>
          <p
            css={{
              fontSize: fontSize.h7,
              color: color.textIcon.light,
            }}
          >
            Variable {getVariableCode(index)}
          </p>
        </div>
        {RIF(
          variableList.length > 2,
          <ButtonCancel size='small' onClick={handleClose} bgColor={color.surface.grey.light} />,
        )}
      </div>
      <Select
        options={dataTypeOptions}
        value={dataTypeValue}
        onChange={setDataTypeValue}
        css={{
          marginBottom: '8px',
        }}
      />
      <Select
        options={variableTypeOptions[dataTypeValue.value] || []}
        value={variableTypeValue}
        onChange={setVariableTypeValue}
      />
    </div>
  )
}

interface ButtonAddVariableProps {
  disabled: boolean
  addVariableBlock: () => void
}

const ButtonAddVariable = (props: ButtonAddVariableProps) => {
  const {color, fontSize} = selectTheme()

  const {disabled, addVariableBlock} = props

  return (
    <button
      onClick={addVariableBlock}
      disabled={disabled}
      css={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: color.surface.grey.background,
        padding: '4px 8px 4px 4px',
        cursor: disabled ? 'default' : 'pointer',
        ':hover': {
          backgroundColor: disabled ? color.surface.grey.background : darken(color.surface.grey.background, 10),
        },
      }}
    >
      <img src={disabled ? AddGreyIcon : AddBlackIcon} width={14} />
      <p
        css={{
          color: disabled ? color.disabled : color.textIcon.secondary,
          marginLeft: '4px',
          fontSize: fontSize.h8,
        }}
      >
        Add
      </p>
    </button>
  )
}

const DataSetSelection = (props: {
  key: string
  dataSetSelectionKey: string
  index: number
  selected: DataSetSelectionItem
  subjectList: AnalysisSidebarSubject[]
  showCloseButton: boolean
  onUpdate: (key: string, value: DataSetSelectionItem) => void
  onDelete: (key: string) => void
}) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {dataSetSelectionKey, index, selected, subjectList, showCloseButton, onUpdate, onDelete} = props

  const toOption = (subject: AnalysisSidebarSubject) => ({
    value: subject,
    label: subject.name,
  })

  const subjectOptions = subjectList.map(toOption)

  const handleChangeDuration = (e: [DateObject, DateObject]) => {
    if (_.isArray(e) && e.length === 2) {
      onUpdate(dataSetSelectionKey, {
        ...selected,
        duration: e,
      })
    }
  }

  const handleChangeSubject = (e: {value: AnalysisSidebarSubject; label: string}) => {
    onUpdate(dataSetSelectionKey, {
      ...selected,
      subject: e.value,
    })
  }

  return (
    <>
      {RIF(
        index != 0,
        <p
          css={{
            fontSize: fontSize.h7,
            color: color.textIcon.secondary,
            margin: '8px 0',
            width: '100%',
            textAlign: 'center',
          }}
        >
          Compared with
        </p>,
      )}
      <div
        css={{
          width: '100%',
          borderRadius: '5px',
          border: `1px solid ${color.border._160}`,
          backgroundColor: color.surface.grey.light,
          padding: '12px',
          marginBottom: '8px',
        }}
      >
        {RIF(
          showCloseButton,
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '16px',
            }}
          >
            <p
              css={{
                fontSize: fontSize.h7,
                color: color.textIcon.light,
              }}
            >
              Data Set {getVariableCode(index)}
            </p>
            <ButtonCancel
              size='small'
              onClick={() => onDelete(dataSetSelectionKey)}
              bgColor={color.surface.grey.light}
            />
          </div>,
        )}
        <p
          css={{
            fontSize: fontSize.h7,
            color: color.textIcon.secondary,
            marginBottom: '4px',
          }}
        >
          Participant or Group
        </p>
        <Select
          value={toOption(selected.subject)}
          options={subjectOptions}
          onChange={handleChangeSubject}
          css={{
            marginBottom: '16px',
          }}
        />
        <p
          css={{
            fontSize: fontSize.h7,
            color: color.textIcon.secondary,
            marginBottom: '4px',
          }}
        >
          Duration
        </p>
        <DatePicker
          value={selected.duration}
          onChange={handleChangeDuration}
          range={true}
          rangeHover={true}
          format='MMM D, YYYY'
          maxDate={new Date()}
          style={{
            padding: '6px 12px',
            width: '100%',
            height: '32px',
            fontSize: fontSize.h7,
            fontWeight: fontWeight.medium,
            border: `1px solid ${color.grey_160}`,
            borderRadius: '5px',
            color: color.textIcon.secondary,
            cursor: 'pointer',
          }}
        />
      </div>
    </>
  )
}

const generateSubjectList = (participantList: ParticipantItem[]) => {
  const subjectList: AnalysisSidebarSubject[] = []
  const groupMap = new Map<string, ParticipantGroup[]>()
  for (const participant of participantList) {
    for (const tag of participant.tagList) {
      if (!groupMap.has(tag.value)) groupMap.set(tag.value, [])
      groupMap.get(tag.value)?.push({
        participantId: participant.id,
        participantInsignia: participant.insignia,
        groupName: tag.value,
      })
    }
  }
  for (const [groupName, group] of groupMap) {
    if (group.length >= GROUP_MINIMUM_SIZE) {
      subjectList.push({
        name: groupName,
        type: AnalysisSidebarSubjectType.Group,
        participantIds: group.map((participant) => participant.participantId),
      })
    }
  }

  for (const participant of participantList) {
    subjectList.push({
      name: participant.insignia,
      type: AnalysisSidebarSubjectType.Individual,
      participantId: participant.id,
    })
  }
  return subjectList
}

const findIncomplete = <T, R>(items: T[], iteratee: (item: T) => R): T[] => {
  const values = items.map(iteratee)
  const incompleteIndexes: number[] = []
  for (const [index, value] of Object.entries(values)) {
    const isIncomplete = values.reduce((acc, cur) => (cur == value ? acc + 1 : acc), 0)
    if (isIncomplete % 2 !== 0) {
      incompleteIndexes.push(parseInt(index))
    }
  }
  return _.uniqBy(
    items.filter((_, index) => incompleteIndexes.includes(index)),
    iteratee,
  )
}
