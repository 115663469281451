import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'

import {doPROJECT_LIST_SET, doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'
import {getToken} from './token_fetcher'

export const REQUEST_PROJECT_LIST_FETCH = 'REQUEST_PROJECT_LIST_FETCH'
export const doREQUEST_PROJECT_LIST_FETCH = createDoRequestAction(REQUEST_PROJECT_LIST_FETCH)

export const requestProjectListFetchActionCreators = {
  doREQUEST_PROJECT_LIST_FETCH,
}

export function* requestProjectListFetch({payload, setRequestResult}: any): SagaIterator {
  debug('saga*requestProjectListFetch')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        page: v.number().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PROJECT_LIST_FETCH,
        requestId: payload.requestId,
        error,
      }, setRequestResult),
    )
  }

  const {page} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/project-list-fetch`,
    accessToken,
    data: {
      page,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)

    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PROJECT_LIST_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/project-list-fetch',
        ...result,
        error,
      }, setRequestResult),
    )
  }

  yield put(doPROJECT_LIST_SET(result.payload))

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_PROJECT_LIST_FETCH,
      fromPayload: payload,
      requestId: payload.requestId,
      ...result,
    }, setRequestResult),
  )
}

export function* projectListFetchSaga() {
  yield takeEvery(REQUEST_PROJECT_LIST_FETCH, requestProjectListFetch)
}
