import {WidthProvider} from 'react-grid-layout'
import {RIF} from '../../../lib/render_if'
import {t} from '../../../lib/t'
import {CorrelationResult, Variable} from '../../../shared/analysis'
import {selectTheme} from '../../../store/state/theme'
import ReactGridLayout from 'react-grid-layout'
import {CorrelationGraph} from './correlation_graph'
import {CorrelationCoordinate} from './correlation_coordinate'
import {getVariableCode, getVariableDisplayName} from '../../../lib/chart_data'

const ComparisonTag = (props: {identifier: string; displayName: string; index: number}) => {
  const {identifier, displayName, index} = props
  const {color, fontSize, fontWeight} = selectTheme()

  return (
    <div
      css={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px',
      }}
    >
      {RIF(
        index !== 0,
        <p
          css={{
            fontSize: fontSize.h7,
            marginRight: '8px',
          }}
        >
          vs.
        </p>,
      )}
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          padding: '4px',
          borderRadius: '5px',
          backgroundColor: color.surface.grey.dark,
        }}
      >
        <p
          css={{
            minWidth: '12px',
            minHeight: '12px',
            lineHeight: '12px',
            borderRadius: '50%',
            fontSize: fontSize.h8,
            fontWeight: fontWeight.thick,
            color: color.white,
            backgroundColor: color.surface.dark,
            textAlign: 'center',
            marginRight: '4px',
          }}
        >
          {identifier}
        </p>
        <p
          css={{
            fontSize: fontSize.h7,
            color: color.textIcon.secondary,
          }}
        >
          {displayName}
        </p>
      </div>
    </div>
  )
}

const ComparisonResultEmpty = () => {
  const {color, fontSize} = selectTheme()
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z'
          fill='#9A9BA2'
        />
        <path
          d='M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z'
          fill='#9A9BA2'
        />
        <path
          d='M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z'
          fill='#9A9BA2'
        />
        <path
          d='M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z'
          fill='#9A9BA2'
        />
        <path
          d='M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM12 17.5C14.33 17.5 16.32 16.05 17.12 14H15.45C14.76 15.19 13.48 16 12 16C10.52 16 9.25 15.19 8.55 14H6.88C7.68 16.05 9.67 17.5 12 17.5Z'
          fill='#9A9BA2'
        />
      </svg>
      <div
        css={{
          color: color.textIcon.secondary,
          margin: '8px 0',
        }}
      >
        No Comparison Results
      </div>
      <div
        css={{
          color: color.textIcon.light,
          fontSize: fontSize.h7,
          textAlign: 'center',
        }}
      >
        There are no additional comparison figures for mean values between participants or durations.
      </div>
    </div>
  )
}

const GridWidthProvider = WidthProvider(ReactGridLayout)

export const CorrelationComparisonResult = (
  props: CorrelationResult & {
    displayingVariableList: Variable[]
    selected: CorrelationCoordinate | undefined
    onClickGraph: (selected: CorrelationCoordinate) => void
  },
) => {
  const {displayingVariableList} = props
  const {color, fontWeight} = selectTheme()
  const items =
    props.items.flat().find(({coord}) => coord.row === props.selected?.row && coord.column === props.selected.column)
      ?.sub_graphs ?? []

  return (
    <div
      css={{
        height: '100%',
        width: '244px',
        minWidth: '244px',
        padding: '16px',
        border: `1px solid ${color.border._80}`,
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <p
          css={{
            fontWeight: fontWeight.thick,
            marginBottom: '16px',
          }}
        >
          Comparison Result
        </p>
        {displayingVariableList.map((variable, index) => {
          const identifier = getVariableCode(index)
          const displayName = getVariableDisplayName(variable)
          return <ComparisonTag key={identifier} identifier={identifier} displayName={displayName} index={index} />
        })}
      </div>
      {RIF(items.length === 0, <ComparisonResultEmpty />)}
      {RIF(
        items.length,
        <GridWidthProvider margin={[8, 8]}>
          {items.map((item, rowIndex) => {
            const coord = t.unwrap(props.selected)
            const selected = item.analysis_type === coord.analysisType
            return (
              <div key={rowIndex} data-grid={{x: 0, y: rowIndex, w: 1, h: 1}}>
                <CorrelationGraph
                  {...{
                    coord,
                    item,
                    selected,
                    onClickGraph: props.onClickGraph,
                  }}
                />
              </div>
            )
          })}
        </GridWidthProvider>,
      )}
      <div />
    </div>
  )
}
