import {useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {
  _,
  collaboratorTypeHasRole,
  RIF,
  timeConvert,
  TimeConvertType,
  useClickOutside,
  useCollaboratorTypeInProject,
  useCurrentWorkspaceState,
} from '../../lib'
import {
  createDispatchActions,
  selectFavoriteList,
  selectProfile,
  selectProjectData,
  selectTheme,
  selectWorkspaces,
} from '../../store'
import {ButtonMore, ProjectDuplicatePop, ProjectSimpleDeletePop, StatusTag, ToolTipHover} from '..'
import {StarGreyIcon, StarYellowIcon} from '../../asset/image'
import {ProjectCollaboratorRole, WorkspaceCollaboratorRole} from '../../shared/db'

export interface ProjectCardProps {
  index: number
  project: {
    id: string
    name: string
    status: string
    createdAt: string
    workspaceId: string
  }
}

export const ProjectCard = (props: ProjectCardProps) => {
  const {color, fontWeight, fontSize} = selectTheme()

  const {id, name, status, createdAt, workspaceId} = props.project
  const {index} = props

  const {
    doREQUEST_WORKSPACE_FAVORITE_CREATE,
    doREQUEST_WORKSPACE_FAVORITE_DELETE,
  }: any = createDispatchActions()
  const workspacesState = selectWorkspaces()
  const { isFavoritesOrSharedWithMe } = useCurrentWorkspaceState()
  const collaboratorType = useCollaboratorTypeInProject({ projectId: id })
  const canUpdateProject = collaboratorTypeHasRole({
    collaboratorType,
    projectRole: ProjectCollaboratorRole.ProjectUpdate,
    workspaceRole: WorkspaceCollaboratorRole.ProjectUpdate
  })
  const canDuplicateProject = collaboratorTypeHasRole({
    collaboratorType,
    workspaceRole: WorkspaceCollaboratorRole.ProjectCreate
  })
  const canDeleteProject = collaboratorTypeHasRole({
    collaboratorType,
    workspaceRole: WorkspaceCollaboratorRole.ProjectDelete
  })
  const profile = selectProfile()
  const isOwnWorkspace = workspacesState[workspaceId]?.identityId === profile.identityId

  const navigate = useNavigate()
  const project = selectProjectData()?.[id]
  const favoriteList = selectFavoriteList()
  const isFavoriteProject = !!_.find(
    favoriteList,
    ['projectId', project?.id]
  )

  const [displayBrick, setDisplayBrick] = useState(false)
  const [displayDuplicatePopup, setDisplayDuplicatePopup] = useState(false)
  const [displayDeletePopup, setDisplayDeletePopup] = useState(false)
  const [favoriteButtonHovered, setFavoriteButtonHovered] = useState(false)

  const brickRef = useRef<HTMLDivElement>(null)
  useClickOutside(brickRef, () => setDisplayBrick(false))

  const handleClickFavorite = () => {
    setFavoriteButtonHovered(false)
    if (isFavoriteProject) {
      const findResult = _.find(
        favoriteList,
        ['projectId', project?.id]
      )
      if (!findResult) return
      return doREQUEST_WORKSPACE_FAVORITE_DELETE({
        payload: {
          workspaceFavoriteId: findResult.id,
        }
      })
    }
    doREQUEST_WORKSPACE_FAVORITE_CREATE({
      payload: {
        projectId: project.id
      }
    })
  }

  return (
    <>
      {RIF(
        displayDuplicatePopup,
        <ProjectDuplicatePop
          {...{
            projectName: name,
            projectId: id,
            setDisplayDuplicatePopup,
          }}
        />,
      )}

      {RIF(
        displayDeletePopup,
        <ProjectSimpleDeletePop
          {...{
            projectName: name,
            projectId: id,
            setDisplayDeletePopup,
          }}
        />,
      )}
      <Link
        to={status === 'draft' ? `/project_setup/${id}` : status === 'live' ? `/dashboard/${id}` : ''}
        onClick={(e: any) => {
          if (
            e.target.id === 'displayBrickButtonId' ||
            e.target.id === 'displayDuplicateBrickId' ||
            e.target.id === 'displayDeleteBrickId' ||
            e.target.id === 'favoriteButtonId'
          ) {
            e.preventDefault()
          }
        }}
        {...props}
        css={{
          width: 'calc(33% - 16px)',
          height: '200px',
          background: color.white,
          padding: `${isFavoritesOrSharedWithMe ? '38px' : '16px'} 16px 16px 16px`,
          boxShadow: '0px 4px 12px 0px #D4D4D440',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
          marginBottom: '24px',
          marginRight: index % 3 === 2 ? 0 : '24px',
          ':hover': {
            outline: `1px solid ${color.grey_400}`,
          },
          borderRadius: '5px',
        }}
      >
        {RIF(
          isFavoritesOrSharedWithMe,
          <p css={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            width: '100%',
            padding: '4px 16px',
            backgroundColor: isOwnWorkspace ? color.tag.yellow.light : color.tag.blue.light,
            color: isOwnWorkspace ? color.tag.yellow.dark : color.tag.blue.dark,
            fontWeight: fontWeight.thick,
            fontSize: fontSize.h7,
            borderRadius: '5px 5px 0 0'
          }}>
            {isOwnWorkspace ? 'My Workspace' : workspacesState[workspaceId]?.name ?? ''}
          </p>
        )}
        <div>
          <div css={{
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <div
              css={{
                display: 'flex',
                height: 'fit-content',
              }}
            >
              <StatusTag status={status} css={{marginRight: '10px'}} />
              {RIF(
                status === 'live',
                <div>
                  <>
                    Day {timeConvert({time: new Date(createdAt), type: TimeConvertType.diffInDay})}&nbsp; (Since{' '}
                    {timeConvert({time: new Date(createdAt), type: TimeConvertType.sinceTime})})
                  </>
                </div>,
              )}
            </div>
            <div
              ref={brickRef}
              css={{
                color: color.grey_400,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '24px',
                position: 'relative',
                cursor: 'pointer',
                borderRadius: '4px',
              }}
            >
              <div css={{
                display: 'flex',
                alignItems: 'center',
              }}>
                <button  
                  onMouseEnter={()=>setFavoriteButtonHovered(true)}
                  onMouseLeave={()=>setFavoriteButtonHovered(false)}
                  onClick={handleClickFavorite} 
                  css={{
                    width: '24px',
                    height: '24px',
                    marginRight: '8px',
                    border: 'none',
                    backgroundColor: 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                >
                  {RIF(
                    favoriteButtonHovered,
                    <ToolTipHover
                      hoverRightPosition={isFavoriteProject ? '35%' : '20%'}
                      hoverTopPosition='-45px'
                      pseudoTopPosition='37px'
                      title={isFavoriteProject ? "Remove from Favorite" : "Add to Favorite"}
                    />
                  )}
                  <img id='favoriteButtonId' width='24' src={isFavoriteProject ? StarYellowIcon : StarGreyIcon}/>
                </button>
                {RIF(
                  canUpdateProject,
                  <>
                    <ButtonMore 
                      id='displayBrickButtonId' 
                      onClick={() => setDisplayBrick(!displayBrick)} 
                    />
                    {RIF(
                      displayBrick,
                      <div
                        css={{
                          width: '100px',
                          height: 'fit-content',
                          padding: '5px 10px',
                          border: `1px solid ${color.grey_160}`,
                          borderRadius: '5px',
                          position: 'absolute',
                          right: 0,
                          top: 25,
                          background: color.white,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        {/*  */}
                        {RIF(
                          status === 'live',
                          <>
                            <div
                              onClick={() => navigate(`/settings/${id}`)}
                              id='displayDeleteBrickId'
                              css={{
                                width: '100%',
                                textAlign: 'start',
                                padding: '6px',
                                ':hover': {
                                  background: 'rgba(156, 158, 174, 0.05)',
                                },
                              }}
                            >
                              Settings
                            </div>
                            {RIF(
                              canDuplicateProject,
                              <>
                                <div
                                  onClick={() => setDisplayDuplicatePopup(true)}
                                  id="displayDuplicateBrickId"
                                  css={{
                                    width: '100%',
                                    textAlign: 'start',
                                    padding: '6px',
                                    ':hover': {
                                      background: 'rgba(156, 158, 174, 0.05)',
                                    },
                                  }}
                                >
                                  Duplicate
                                </div>
                              </>,
                            )}
                          </>,
                        )}
                        {RIF(
                          status === 'draft',
                          <>
                            {RIF(
                              canDeleteProject,
                              <>
                                <div
                                  onClick={() => setDisplayDeletePopup(true)}
                                  id="displayDeleteBrickId"
                                  css={{
                                    width: '100%',
                                    textAlign: 'start',
                                    padding: '6px',
                                    ':hover': {
                                      background: 'rgba(156, 158, 174, 0.05)',
                                    },
                                  }}
                                >
                                  Delete
                                </div>
                              </>,
                            )}
                            {RIF(
                              canDuplicateProject,
                              <>
                                <div
                                  onClick={() => setDisplayDuplicatePopup(true)}
                                  id="displayDuplicateBrickId"
                                  css={{
                                    width: '100%',
                                    textAlign: 'start',
                                    padding: '6px',
                                    ':hover': {
                                      background: 'rgba(156, 158, 174, 0.05)',
                                    },
                                  }}
                                >
                                  Duplicate
                                </div>
                              </>,
                            )}
                          </>,
                        )}
                      </div>,
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            css={{
              marginTop: '16px',
              height: '50px',
              fontSize: '18px',
              wordBreak: 'break-word',
              fontWeight: fontWeight.bold,
            }}
          >
            {name}
          </div>
        </div>
        <div>
          {RIF(
            status === 'completed' || status === 'live',
            <div css={{marginRight: '35px'}}>
              <div
                css={{
                  marginTop: '13px',
                  color: color.grey_400,
                }}
              >
                Active Participant(s)
              </div>
              <div css={{marginTop: '6px'}}>{project?.activatedParticipantCount ?? 0}</div>
            </div>,
          )}
        </div>
      </Link>
    </>
  )
}
