import {CSSProperties} from 'react'
import {
  CorrelationResult,
  CorrelationResultAnalysisType,
  CorrelationResultCorrelationData,
} from '../../../shared/analysis'
import ReactGridLayout, {WidthProvider} from 'react-grid-layout'
import {CorrelationGraph} from './correlation_graph'
import {CorrelationCoordinate} from './correlation_coordinate'

const GridWidthProvider = WidthProvider(ReactGridLayout)

export const CorrelationMatrix = (
  props: CorrelationResult & {
    itemStyle?: CSSProperties
    selected: CorrelationCoordinate | undefined
    onClickGraph: (coord: CorrelationCoordinate) => void
  },
) => {
  const {items, selected, onClickGraph} = props
  return (
    <GridWidthProvider cols={props.items[0].length} rowHeight={188} margin={[8, 8]}>
      {items.flatMap((row, rowIndex) => {
        return row.map((item, columnIndex) => {
          const {coord, graph, data} = item
          const correlationData = data.filter(
            ({analysis_type}) => analysis_type === CorrelationResultAnalysisType.Correlation,
          ) as CorrelationResultCorrelationData[]
          const graphSelected = selected?.row === coord.row && selected?.column === coord.column
          return (
            <div
              key={`${rowIndex}-${columnIndex}`}
              data-grid={{x: columnIndex, y: rowIndex, w: 1, h: 1, static: true}}
              style={props.itemStyle}
            >
              <CorrelationGraph
                selected={graphSelected}
                onClickGraph={onClickGraph}
                coord={coord}
                item={graph}
                data={correlationData}
              />
            </div>
          )
        })
      })}
    </GridWidthProvider>
  )
}
