import {useState, useEffect, useMemo} from 'react'
import {useNavigate} from 'react-router-dom'
import {_, RIF, setSessionStorage, useCurrentWorkspaceAccountPlan, sortBy, RequestResult} from '../../lib'

import {DexcomIntegration, ProjectState} from '../../model'

import {
  selectTheme,
  createDispatchActions,
  selectProfile,
  selectWorkspaces,
  selectCollaborators,
  selectProjectData,
  selectFavoriteList,
} from '../../store'

import {
  Button,
  Tooltip,
  ButtonText,
  ProjectCard,
  ProjectCreatePop,
  CollaboratorPage,
  Avatars,
  ButtonReverse,
  WorkspaceSettings,
  WorkspaceSidebar,
  PricingPage,
  PopupCreateProject,
} from '..'

import {
  PlusIcon,
  CollaborateAddIcon,
  WorkspaceBackground,
  CheckSuccessIcon,
  WarningIcon,
  PlayButtonIcon,
  ThunderWhiteIcon,
  FavoriteEmptyState,
  SharedWithMeEmptyState,
  GettingStarted,
  LinkToBlueIcon,
  PlusBlueIcon,
  HeartRateWhiteIcon,
} from '../../asset/image'
import {useCurrentWorkspaceState} from '../../lib/custom_hooks/useCurrentWorkspaceState'
import {IProject, ProjectStatus} from '../../shared/db'

export const ProjectLandingPage = () => {
  const {pad, color, fontWeight, fontSize} = selectTheme()

  /* ------------------ req ------------------ */
  const {
    doREQUEST_DEXCOM_INTEGRATION_LIST_FETCH,
    doREQUEST_GLOBAL_WORKSPACE_LIST_FETCH,
    doREQUEST_PROJECT_LIST_FETCH,
    doREQUEST_PROJECT_UPDATE,
    doREQUEST_PROJECT_CREATE,
  }: any = createDispatchActions()

  const [requestResultOfProjectUpdate, setRequestResultOfProjectUpdate] = useState<RequestResult | null>(null)

  /* ------------------ reducer & store basic state ------------------ */
  const profile = selectProfile()
  const workspacesState = selectWorkspaces()
  const collaboratorsState = selectCollaborators()
  const favoriteList = selectFavoriteList()
  const {
    isFavoritesOrSharedWithMe,
    workspaceId: currentWorkspaceId,
    workspace: currentWorkspace,
  } = useCurrentWorkspaceState()
  const {globalWorkspacesList} = workspacesState
  const isOwnWorkspace = currentWorkspace?.ownerProfile?.email === profile?.email
  const dexcomIntegrationList = currentWorkspace?.dexcomIntegrationList || []
  const approvedDexcomIntegrationList =
    dexcomIntegrationList.filter((dexcomIntegration: DexcomIntegration) => dexcomIntegration?.dexcomApp) || []
  const appendingDexcomIntegrationList =
    dexcomIntegrationList.filter((dexcomIntegration: DexcomIntegration) => !dexcomIntegration?.dexcomApp) || []
  const role = workspacesState?.roleInWorkspaces[currentWorkspaceId ?? ''] || 'owner'
  const projectData = selectProjectData()
  const navigate = useNavigate()
  const userPermission = workspacesState.roleInWorkspaces[currentWorkspaceId ?? ''] || 'owner'

  /* ------------------ basic state ------------------ */
  const {accountPlan, hasActivePlanPurchase} = useCurrentWorkspaceAccountPlan()
  const participantLimit = hasActivePlanPurchase ? currentWorkspace?.participantLimit : 5

  const [projectList, setProjectList] = useState<ProjectState[]>([])
  const [collaboratorList, setCollaboratorList] = useState<any[]>([])

  // display other component
  const [displayCreatePopup, setDisplayCreatePopup] = useState(false)
  const [displayCollaboratorsPage, setDisplayCollaboratorsPage] = useState(false)
  const [displayChangePlanPage, setDisplayChangePlanPage] = useState(false)
  const [displayPopupCreateProject, setDisplayPopupCreateProject] = useState(false)

  // display workspace settings
  const [isWorkspaceSettingsPage, setIsWorkspaceSettingsPage] = useState(false)
  const [requestResultOfProjectCreate, setRequestResultOfProjectCreate] = useState<RequestResult | null>(null)

  /* ------------------ default effect ------------------ */
  useEffect(() => {
    if (!profile?.id) navigate(`/account_setup?profile_initial_settings_page`)
    window.scrollTo({top: 0, behavior: 'smooth'})

    doREQUEST_GLOBAL_WORKSPACE_LIST_FETCH({})
  }, [])

  // set ProjectList from projectData, filter deleted and current workspace, sorted
  // todo update this function
  useMemo(() => {
    if (displayCreatePopup) return
    if (currentWorkspaceId && !!Object.values(projectData)?.length) {
      const listWithoutDeletedProject = Object.values(projectData).filter((item: any) => item.status !== 'deleted')
      let listOfProjectInCurrentWorkspace: ProjectState[]
      if (currentWorkspaceId === 'favorites') {
        listOfProjectInCurrentWorkspace = []
        favoriteList.forEach((e) => {
          const findResult = _.find(listWithoutDeletedProject, ['id', e.projectId])
          if (findResult) listOfProjectInCurrentWorkspace.push(findResult as ProjectState)
        })
        listOfProjectInCurrentWorkspace.sort(sortBy('createdAt')).reverse()
      } else if (currentWorkspaceId === 'sharedWithMe') {
        listOfProjectInCurrentWorkspace = (listWithoutDeletedProject as ProjectState[])
          .filter((item: any) => workspacesState.roleInWorkspaces[item.workspaceId] === 'project_collaborator')
          .sort(sortBy('createdAt'))
          .reverse()
      } else {
        listOfProjectInCurrentWorkspace = (listWithoutDeletedProject as ProjectState[])
          .filter((item: any) => item.workspaceId === currentWorkspaceId)
          .sort(sortBy('createdAt'))
          .reverse()
      }
      setProjectList([...listOfProjectInCurrentWorkspace])
      // doLOADING_SET({type: 'counting participantsAmount from batch data'})
    }
  }, [projectData, currentWorkspaceId, favoriteList])

  const {workspace} = useCurrentWorkspaceState()
  const participantsAmount = workspace?.totalParticipants ?? 0

  useEffect(() => {
    setSessionStorage({totalParticipants: participantsAmount})
  }, [participantsAmount])

  const createNewProject = () => {
    doREQUEST_PROJECT_CREATE({
      setRequestResult: setRequestResultOfProjectCreate,
      payload: {
        workspaceId: currentWorkspace?.id,
        name: 'My Project',
      },
    })
  }

  useEffect(() => {
    if (requestResultOfProjectCreate?.success) {
      const projectId = requestResultOfProjectCreate?.result.payload?.batchList?.[0]?.projectId
      if (projectId) return navigate(`/project_setup/${projectId}`)
    }
  }, [requestResultOfProjectCreate])

  // set collaborator list
  useEffect(() => {
    if (currentWorkspaceId && collaboratorsState[currentWorkspaceId]) {
      const tempCollaboratorList = Object.values(collaboratorsState[currentWorkspaceId]).filter((c) => c.profile?.id)
      setCollaboratorList(tempCollaboratorList)
    }
  }, [collaboratorsState, currentWorkspaceId])

  /* ------------------ request ------------------ */

  useEffect(() => {
    if (!currentWorkspaceId) return
    doREQUEST_PROJECT_LIST_FETCH({
      payload: {
        page: 0,
      },
    })

    doREQUEST_DEXCOM_INTEGRATION_LIST_FETCH({
      payload: {
        workspaceId: currentWorkspaceId,
      },
    })
  }, [currentWorkspaceId])

  /* ------------------ other effect ------------------ */
  // update project from localStorage then clear
  const projectContent = JSON.parse(localStorage.getItem('labfront_project_content') || '{}')
  useEffect(() => {
    if (!!projectContent?.id && role !== 'viewer') {
      doREQUEST_PROJECT_UPDATE({
        setRequestResult: setRequestResultOfProjectUpdate,
        payload: {
          projectId: projectContent.id,
          name: projectContent.name,
          investigator: projectContent.investigator,
          organization: projectContent.organization,
          description: projectContent.description,
          contactDescription: projectContent.contactValue || '',
          participantInstructions: projectContent.instructionValue || '',
        },
      })
    }
  }, [])

  useEffect(() => {
    if (requestResultOfProjectUpdate?.success) {
      localStorage.removeItem('labfront_project_content')
    }
  }, [requestResultOfProjectUpdate])

  return (
    <>
      {RIF(displayCreatePopup, <ProjectCreatePop {...{setDisplayCreatePopup}} />)}
      {RIF(displayCollaboratorsPage, <CollaboratorPage {...{setDisplayCollaboratorsPage}} />)}
      {RIF(displayChangePlanPage, <PricingPage {...{setDisplayChangePlanPage}} />)}
      {RIF(displayPopupCreateProject, <PopupCreateProject {...{setDisplayPopupCreateProject}} />)}
      {RIF(
        isWorkspaceSettingsPage,
        <WorkspaceSettings
          {...{
            setIsWorkspaceSettingsPage,
            scrollToDexcom: true,
          }}
        />,
      )}

      {/* page container */}
      <div
        css={{
          minHeight: '100vh',
          background: color.background,
          position: 'relative',
        }}
      >
        <WorkspaceSidebar />
        {/* favorite */}
        {RIF(
          currentWorkspaceId === 'favorites',
          <div
            css={{
              padding: '30px 30px 30px 286px',
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
              width: '100vw',
            }}
          >
            <p
              css={{
                fontSize: fontSize.h3,
                fontWeight: fontWeight.thick,
                marginBottom: '16px',
              }}
            >
              Favorites
            </p>
            {RIF(
              projectList.length,
              <div
                css={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                {projectList.map((project, index) => (
                  <ProjectCard
                    {...{
                      key: project.id,
                      index,
                      project,
                    }}
                  />
                ))}
              </div>,
            )}
            {RIF(
              !projectList.length,
              <div
                css={{
                  flex: 1,
                  borderRadius: '4px',
                  border: `1px dashed ${color.grey_400}`,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img css={{marginBottom: '24px'}} width='244' src={FavoriteEmptyState} />
                <p
                  css={{
                    color: color.textIcon.secondary,
                    fontWeight: fontWeight.thick,
                    fontSize: fontSize.h4,
                    marginBottom: '8px',
                  }}
                >
                  No favorite projects yet
                </p>
                <p
                  css={{
                    color: color.textIcon.light,
                    fontSize: fontSize.h5,
                  }}
                >
                  Star projects and they’ll appear here for easy access.
                </p>
              </div>,
            )}
          </div>,
        )}
        {/* shared with me */}
        {RIF(
          currentWorkspaceId === 'sharedWithMe',
          <div
            css={{
              padding: '30px 30px 30px 286px',
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
              width: '100vw',
            }}
          >
            <p
              css={{
                fontSize: fontSize.h3,
                fontWeight: fontWeight.thick,
                marginBottom: '16px',
              }}
            >
              Shared with me
            </p>
            {RIF(
              !projectList.length,
              <div
                css={{
                  flex: 1,
                  borderRadius: '4px',
                  border: `1px dashed ${color.grey_400}`,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img css={{marginBottom: '24px'}} width='244' src={SharedWithMeEmptyState} />
                <p
                  css={{
                    color: color.textIcon.secondary,
                    fontWeight: fontWeight.thick,
                    fontSize: fontSize.h4,
                    marginBottom: '8px',
                  }}
                >
                  No shared with me projects
                </p>
                <p
                  css={{
                    color: color.textIcon.light,
                    fontSize: fontSize.h5,
                  }}
                >
                  Projects shared with you by others will be displayed here.
                </p>
              </div>,
            )}
            {RIF(
              projectList.length,
              <div
                css={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                {projectList.map((project, index) => (
                  <ProjectCard
                    {...{
                      key: project.id,
                      index,
                      project,
                    }}
                  />
                ))}
              </div>,
            )}
          </div>,
        )}
        {/* workspace */}
        {RIF(
          currentWorkspaceId && !isFavoritesOrSharedWithMe,
          <div
            css={{
              padding: '30px 30px 30px 286px',
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
              width: '100vw',
            }}
          >
            {/* top state */}
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '16px',
              }}
            >
              {/* workspace name + upgrade button + workspace settings button */}
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  css={{
                    fontSize: fontSize.h3,
                    fontWeight: fontWeight.bold,
                    marginRight: '16px',
                  }}
                >
                  {currentWorkspace?.name}
                </div>
                {/* upgrade button */}
                {RIF(
                  isOwnWorkspace && (accountPlan === 'Free' || !hasActivePlanPurchase),
                  <Button
                    onClick={() => setDisplayChangePlanPage(true)}
                    btnColor='upgrade'
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: '16px',
                      fontSize: fontSize.h7,
                      fontWeight: fontWeight.medium,
                      padding: '4px 8px 4px 4px',
                    }}
                  >
                    <>
                      <img css={{marginRight: '4px'}} height='16' width='16' src={ThunderWhiteIcon} />
                      Upgrade
                    </>
                  </Button>,
                )}
                {/* workspace settings button */}
                {RIF(
                  !userPermission.includes('project'),
                  <button
                    onClick={() => setIsWorkspaceSettingsPage(true)}
                    css={{
                      fontSize: fontSize.h7,
                      color: color.textIcon.secondary,
                      border: `1px solid ${color.textIcon.secondary}`,
                      borderRadius: '3px',
                      background: 'transparent',
                      cursor: 'pointer',
                      padding: '4px 8px',
                      ':hover': {
                        background: color.surface.hover.white,
                      },
                    }}
                  >
                    Workspace Settings
                  </button>,
                )}
              </div>
              {/* create project button */}
              {RIF(
                role !== 'viewer',
                <Button
                  btnPadding='medium'
                  onClick={() => {
                    if (process.env.DEPLOY_STAGE === 'develop' || process.env.DEPLOY_STAGE === 'staging') {
                      setDisplayPopupCreateProject(true)
                    } else {
                      setDisplayCreatePopup(true)
                    }
                  }}
                >
                  <>
                    <img src={PlusIcon} width='11' css={{marginRight: '8px'}} />
                    Create New Project
                  </>
                </Button>,
              )}
            </div>
            {/* tutorial block */}
            <div
              css={{
                marginBottom: '16px',
                borderRadius: '5px',
                backgroundColor: color.surface.grey.dark,
                padding: '16px',
                width: '100%',
              }}
            >
              <div
                css={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p
                  css={{
                    fontWeight: fontWeight.thick,
                  }}
                >
                  Get Started with Labfront
                </p>
                <p
                  css={{
                    color: color.textIcon.secondary,
                    fontWeight: fontWeight.thick,
                    fontSize: fontSize.h7,
                  }}
                >
                  Learn more: Visit&nbsp;
                  <a
                    href='https://help.labfront.com/'
                    target='_blank'
                    css={{
                      textDecoration: 'underline',
                      color: color.primary,
                      fontWeight: fontWeight.thick,
                      fontSize: fontSize.h6,
                    }}
                  >
                    Help Center
                  </a>
                  &nbsp;or&nbsp;
                  <a
                    href='https://help.labfront.com/kb-tickets/new'
                    target='_blank'
                    css={{
                      textDecoration: 'underline',
                      color: color.primary,
                      fontWeight: fontWeight.thick,
                      fontSize: fontSize.h6,
                    }}
                  >
                    Schedule a Call
                  </a>
                </p>
              </div>
              <div
                css={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'calc(50% - 8px)',
                    borderRadius: '5px',
                    backgroundColor: color.white,
                    padding: '16px',
                  }}
                >
                  <p
                    css={{
                      width: '32px',
                      minWidth: '32px',
                      height: '32px',
                      minHeight: '32px',
                      borderRadius: '50%',
                      textAlign: 'center',
                      lineHeight: '32px',
                      backgroundColor: color.surface.grey.dark,
                      color: color.textIcon.secondary,
                      fontWeight: fontWeight.thick,
                      fontSize: fontSize.h5,
                    }}
                  >
                    1
                  </p>
                  <div css={{margin: '0 16px'}}>
                    <p
                      css={{
                        fontWeight: fontWeight.thick,
                        marginBottom: '8px',
                      }}
                    >
                      Watch Labfront Walkthrough
                    </p>
                    <p
                      css={{
                        fontSize: fontSize.h7,
                        color: color.grey_600,
                      }}
                    >
                      We’ll guide you through the key steps to get started with Labfront
                    </p>
                  </div>
                  <VideoThumbnail videoId='yAOEdMIC_o4' />
                </div>
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'calc(50% - 8px)',
                    borderRadius: '5px',
                    backgroundColor: color.white,
                    padding: '16px',
                  }}
                >
                  <p
                    css={{
                      width: '32px',
                      minWidth: '32px',
                      height: '32px',
                      minHeight: '32px',
                      borderRadius: '50%',
                      textAlign: 'center',
                      lineHeight: '32px',
                      backgroundColor: color.surface.grey.dark,
                      color: color.textIcon.secondary,
                      fontWeight: fontWeight.thick,
                      fontSize: fontSize.h5,
                    }}
                  >
                    2
                  </p>
                  <div css={{margin: '0 16px'}}>
                    <p
                      css={{
                        fontWeight: fontWeight.thick,
                        marginBottom: '8px',
                      }}
                    >
                      Getting Started
                    </p>
                    <p
                      css={{
                        fontSize: fontSize.h7,
                        marginBottom: '8px',
                        color: color.grey_600,
                      }}
                    >
                      Everything you need to get started
                    </p>
                    <a
                      href='https://help.labfront.com/getting-started'
                      target='_blank'
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        color: color.primary,
                        fontWeight: fontWeight.thick,
                        fontSize: fontSize.h7,
                      }}
                    >
                      Check it out
                      <img css={{marginLeft: '4px'}} width='16' src={LinkToBlueIcon} />
                    </a>
                  </div>
                  {/* <VideoThumbnail videoId="UqBiEBV6KTY"/> */}
                  <img width='112' src={GettingStarted} />
                </div>
              </div>
            </div>
            {/* project list + workspace info */}
            <div css={{display: 'flex', alignItems: 'center'}}>
              <div css={{paddingRight: '15px', fontWeight: fontWeight.medium}}>
                Total {projectList?.length} project{projectList?.length === 1 ? '' : 's'}
              </div>
              <div
                css={{
                  borderLeft: `1px solid ${color.grey_160}`,
                  borderRight: userPermission.includes('project') ? 'none' : `1px solid ${color.grey_160}`,
                  padding: '0 15px',
                  display: 'flex',
                }}
              >
                <span data-testid='participants_amount'>{participantsAmount}</span>
                &nbsp;/ {participantLimit} participant slots used
                <Tooltip
                  css={{marginLeft: '3px'}}
                  {...{
                    position: 'up',
                    content: !hasActivePlanPurchase
                      ? `Since you're on the Free plan, you can add up to 5 participants throughout all projects.`
                      : accountPlan === 'Advanced'
                      ? `Since you're on the Advanced plan, you can add up to 20 participants throughout all projects.
                    
                    To add additional participants please go to workspace settings to buy more participant slots.`
                      : accountPlan === 'Basic'
                      ? `Since you're on the Basic plan, you can add up to 20 participants throughout all projects. `
                      : `Since you're on the Free plan, you can add up to 5 participants throughout all projects.`,
                  }}
                />
              </div>
              {RIF(
                !collaboratorList.length && !userPermission.includes('project'),
                <ButtonText
                  onClick={() => setDisplayCollaboratorsPage(true)}
                  btnPadding='medium'
                  css={{marginLeft: '8px'}}
                >
                  <>
                    <img src={CollaborateAddIcon} width='20' />
                    <span css={{color: color.primary, marginLeft: '8px', fontWeight: fontWeight.thick}}>
                      Add Collaborator
                    </span>
                  </>
                </ButtonText>,
              )}
              {RIF(
                !!collaboratorList.length && !userPermission.includes('project'),
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: pad.slightlyLarger,
                  }}
                >
                  <p css={{fontWeight: fontWeight.medium}}>
                    Total&nbsp;
                    <span data-testid='collaborator_amount' css={{fontWeight: fontWeight.medium}}>
                      {collaboratorList.length}
                    </span>
                    &nbsp;Collaborators
                  </p>
                  <Avatars
                    onClick={() => setDisplayCollaboratorsPage(true)}
                    css={{
                      marginRight: pad.small,
                      cursor: 'pointer',
                    }}
                    users={collaboratorList}
                  />
                  <ButtonReverse
                    onClick={() => setDisplayCollaboratorsPage(true)}
                    btnPadding='small'
                    btnColor='grey_600'
                    children='Invite'
                  />
                </div>,
              )}
              {RIF(
                dexcomIntegrationList.length,
                <div
                  css={{
                    marginLeft: '15px',
                    padding: '0 15px',
                    borderLeft: `1px solid ${color.grey_160}`,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <p
                    css={{
                      fontWeight: fontWeight.medium,
                    }}
                  >
                    Dexcom Integration:
                  </p>
                  {RIF(
                    approvedDexcomIntegrationList.length,
                    <p
                      css={{
                        fontWeight: fontWeight.thick,
                        color: color.taskGreen,
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '8px',
                      }}
                    >
                      <img src={CheckSuccessIcon} width={16} />
                      &nbsp;{approvedDexcomIntegrationList.length} Approved
                    </p>,
                  )}
                  {RIF(
                    appendingDexcomIntegrationList.length,
                    <p
                      css={{
                        fontWeight: fontWeight.thick,
                        color: color.warning,
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '8px',
                      }}
                    >
                      <img src={WarningIcon} width={16} />
                      &nbsp;{appendingDexcomIntegrationList.length} Awaiting approval
                    </p>,
                  )}
                  <div
                    css={{
                      marginLeft: '4px',
                      marginRight: '8px',
                      width: '16px',
                    }}
                  >
                    <Tooltip content='Your application is pending Dexcom approval. Please check your email for updates, or contact us at hello@labfront.com with any questions.' />
                  </div>
                  <ButtonReverse
                    onClick={() => {
                      setIsWorkspaceSettingsPage(true)
                    }}
                    btnPadding='small'
                    btnColor='grey_400'
                    children='View'
                  />
                </div>,
              )}
            </div>

            {RIF(
              !projectList.length,
              <div
                css={{
                  marginTop: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flex: 1,
                  border: `1px dashed ${color.grey_160}`,
                  borderRadius: '5px',
                }}
              >
                {RIF(
                  process.env.DEPLOY_STAGE !== 'develop' && process.env.DEPLOY_STAGE !== 'staging',
                  <div
                    css={{
                      width: 'fit-content',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <img src={WorkspaceBackground} width='180px' height='110px' />
                    <p
                      css={{
                        fontSize: fontSize.h4,
                        marginTop: '35px',
                      }}
                    >
                      No projects yet
                    </p>
                    <p
                      css={{
                        fontSize: fontSize.h5,
                        color: color.textIcon.light,
                        textAlign: 'center',
                      }}
                    >
                      Create a project to start collecting data and
                      <br />
                      discover the next big thing in science!
                    </p>
                  </div>,
                )}
                {RIF(
                  process.env.DEPLOY_STAGE === 'develop' || process.env.DEPLOY_STAGE === 'staging',
                  <>
                    <div
                      css={{
                        fontSize: '18px',
                        color: color.textIcon.secondary,
                      }}
                    >
                      No projects yet
                    </div>
                    <div
                      css={{
                        fontSize: '16px',
                        color: color.textIcon.light,
                        textAlign: 'center',
                        marginTop: '8px',
                      }}
                    >
                      Create a project to start collecting data and
                      <br /> discover the next big thing in science!
                    </div>
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '32px',
                      }}
                    >
                      <button
                        onClick={createNewProject}
                        css={{
                          height: '166px',
                          width: '160px',
                          borderRadius: '5px',
                          border: `1px dashed ${color.textIcon.link}`,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          backgroundColor: 'transparent',
                          margin: '0 8px',
                          padding: '0 24px',
                          cursor: 'pointer',
                        }}
                      >
                        <img css={{marginBottom: '8px'}} src={PlusBlueIcon} width='24' />
                        <p
                          css={{
                            fontWeight: fontWeight.thick,
                            fontSize: fontSize.h5,
                            color: color.textIcon.link,
                          }}
                        >
                          Start from scratch
                        </p>
                      </button>
                      {globalWorkspacesList.map((workspace) => {
                        return _.sampleSize(
                          workspace.projectList
                            .filter((project) => project.status !== ProjectStatus.Deleted)
                            .map((project) => {
                              return <TemplateProjectCard {...{project}} />
                            }),
                          2,
                        )
                      })}
                    </div>
                  </>,
                )}
              </div>,
            )}
            {RIF(
              projectList.length,
              <>
                <div
                  css={{
                    marginTop: '16px',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  {projectList.map((project, index) => {
                    return (
                      <ProjectCard
                        {...{
                          key: project.id,
                          index,
                          project,
                        }}
                      />
                    )
                  })}
                  {RIF(projectList.length % 3 !== 0, <div css={{width: '31.8%', height: '220px'}}></div>)}
                </div>
              </>,
            )}
          </div>,
        )}
      </div>
    </>
  )
}

interface VideoThumbnailProps {
  videoId: string
}

const VideoThumbnail = (props: VideoThumbnailProps) => {
  const {videoId} = props
  const handleClick = () => {
    window.open(`https://www.youtube.com/watch?v=${videoId}`, '_blank')
  }

  return (
    <div
      onClick={handleClick}
      style={{
        cursor: 'pointer',
        width: '137px',
        height: '72px',
        display: 'flex',
        borderRadius: '8px',
        backgroundImage: `url(https://img.youtube.com/vi/${videoId}/0.jpg)`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        overflow: 'hidden',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          background: '#00000040',
        }}
      >
        <img width='40' src={PlayButtonIcon} />
      </div>
    </div>
  )
}

interface TemplateProjectCardProps {
  project: IProject
}

const TemplateProjectCard = (props: TemplateProjectCardProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {project} = props
  const {doREQUEST_PROJECT_DUPLICATE}: any = createDispatchActions()
  const navigate = useNavigate()
  const workspacesState = selectWorkspaces()
  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)

  const duplicateTemplateProject = () => {
    if (!workspacesState?.currentWorkspaceId) return
    doREQUEST_PROJECT_DUPLICATE({
      setRequestResult,
      payload: {
        projectId: project.id,
        projectName: project.name,
        workspaceId: workspacesState.currentWorkspaceId,
      },
    })
  }

  useEffect(() => {
    if (requestResult?.success) {
      const projectId = requestResult?.result.payload?.id
      if (projectId) return navigate(`/project_setup/${projectId}`)
    }
  }, [requestResult])

  return (
    <button
      onClick={duplicateTemplateProject}
      css={{
        width: '160px',
        height: '166px',
        padding: '16px',
        backgroundColor: color.white,
        borderRadius: '5px',
        margin: '8px',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <div
        css={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <div
          css={{
            height: '24px',
            width: '24px',
            borderRadius: '4px',
            backgroundColor: color.surface.orange.background,
            backgroundImage: `url(${HeartRateWhiteIcon})`,
            backgroundSize: '20px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <p
          css={{
            fontSize: fontSize.h7,
            fontWeight: fontWeight.thick,
            color: color.textIcon.link,
          }}
        >
          Use Template
        </p>
      </div>
      <div>
        <p
          css={{
            marginBottom: '8px',
            textAlign: 'left',
          }}
        >
          {project.name}
        </p>
        <p
          css={{
            color: color.textIcon.secondary,
            fontSize: fontSize.h7,
            textAlign: 'left',
          }}
        >
          {project.description}
        </p>
      </div>
    </button>
  )
}
