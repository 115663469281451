import { Dispatch, SetStateAction } from 'react'
import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
  RequestResult,
} from '../../lib'
import {CorrelationConfig, Variable} from '../../shared/analysis'

import {doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_ANALYTIC_GRAPH_CORRELATION_MULTIPLE_INDIVIDUAL_FETCH =
  'REQUEST_ANALYTIC_GRAPH_CORRELATION_MULTIPLE_INDIVIDUAL_FETCH'
export const doREQUEST_ANALYTIC_GRAPH_CORRELATION_MULTIPLE_INDIVIDUAL_FETCH = createDoRequestAction(
  REQUEST_ANALYTIC_GRAPH_CORRELATION_MULTIPLE_INDIVIDUAL_FETCH,
)

export const requestAnalyticGraphCorrelationMultipleIndividualFetchActionCreators = {
  doREQUEST_ANALYTIC_GRAPH_CORRELATION_MULTIPLE_INDIVIDUAL_FETCH,
}

export function* requestAnalyticGraphCorrelationMultipleIndividualFetch({
  payload,
  type,
  setRequestResult
}: {
  payload: {
    requestId: string
    participantIds: string[]
    yymmddIndexStart: number
    yymmddIndexEnd: number
    variables: Variable[]
    config?: CorrelationConfig
  }
  type: string
  setRequestResult: Dispatch<SetStateAction<RequestResult>>
}): SagaIterator {
  debug('saga*requestAnalyticGraphCorrelationMultipleIndividualFetch')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        participantIds: v.array().items(v.string().uuid().required()).min(2).required(),
        yymmddIndexStart: v.number().integer().positive().required(),
        yymmddIndexEnd: v.number().integer().positive().required(),
        variables: v
          .array()
          .min(2)
          .max(4)
          .items(
            v
              .object({
                identifier: v.string().exist(),
              })
              .unknown(true)
              .required(),
          )
          .required(),
        config: v
          .object({
            color_discreate_sequence: v.array().items(v.string()).optional(),
          })
          .optional(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_ANALYTIC_GRAPH_CORRELATION_MULTIPLE_INDIVIDUAL_FETCH,
        requestId: payload.requestId,
        error,
      }, setRequestResult),
    )
  }

  const {participantIds, yymmddIndexStart, yymmddIndexEnd, variables, config} = payload
  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/analytic-graph-correlation-multiple-individual-fetch`,
    accessToken,
    data: {
      participantIds,
      yymmddIndexStart,
      yymmddIndexEnd,
      variables,
      config,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_ANALYTIC_GRAPH_CORRELATION_MULTIPLE_INDIVIDUAL_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/analytic-graph-correlation-multiple-individual-fetch',
        ...result,
        error,
      }, setRequestResult),
    )
  }

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_ANALYTIC_GRAPH_CORRELATION_MULTIPLE_INDIVIDUAL_FETCH,
      fromPayload: payload,
      requestId: payload.requestId,
      ...result,
    }, setRequestResult),
  )
}

export function* analyticGraphCorrelationMultipleIndividualFetchSaga() {
  yield takeEvery(
    REQUEST_ANALYTIC_GRAPH_CORRELATION_MULTIPLE_INDIVIDUAL_FETCH,
    requestAnalyticGraphCorrelationMultipleIndividualFetch,
  )
}
