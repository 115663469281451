import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Controller, SubmitHandler} from 'react-hook-form'
import {
  createDispatchActions,
  selectMethod,
  selectQuestionnaires,
  selectTempTaskData,
  selectTheme,
} from '../../store'
import {
  _,
  camelToSnake,
  collaboratorTypeHasRole,
  RIF,
  t,
  useCollaboratorTypeInProject,
  useCurrentProjectState,
  useCurrentWorkspaceState,
  useForm,
  v,
} from '../../lib'
import {DeviceIcon, SurveyBlackIcon, TimerBlackIcon, TodoBlackIcon} from '../../asset/image'
import MovesenseImg from '../../asset/image/movesense.png'
import {
  ContentListItem,
  ContentListItemCategory,
  EditingTask,
  getTaskContentName,
  Question,
  Questionnaire,
  Section,
  TaskReminder,
  TaskStateType,
  TaskStopwatchGarminStreamCopyT,
  TaskStopwatchGarminStreamT,
  TaskStopwatchMovesenseStreamCopyT,
  TaskStopwatchMovesenseStreamT,
  TaskTempStateType,
  TaskType,
  TaskTypeForInstructionPage,
} from '../../model'
import {
  AddTaskAppPreviewSection,
  AddTaskGarminStreamSection,
  AddTaskMovesenseSection,
  AddTaskReminderSection,
  AddTaskScheduleSection,
  Button,
  ButtonCancel,
  ColorSelector,
  Input,
  Label,
  LabelBox,
  PopupConfirmDeleteTask,
  PopupConfirmDiscardTask,
  PopupConfirmShowTask,
  RequiredTag,
  Textarea,
  TextLink,
} from '..'
import {ProjectCollaboratorRole, WorkspaceCollaboratorRole} from '../../shared/db'

export const taskPlaceholder = (
  taskType:
    | 'todo'
    | 'questionnaire'
    | 'timer'
    | 'garminDevice'
    | 'stopwatchMovesenseStream'
    | 'stopwatchMovesenseLogData'
    | 'stopwatchGarminStream'
    | 'dexcom',
) => {
  let taskNamePlaceholder = ''
  let taskDescriptionPlaceholder = ''
  switch (taskType) {
    case 'todo':
      taskNamePlaceholder = 'Morning Intervention'
      taskDescriptionPlaceholder =
        'After taking morning dose of intervention, mark this as complete to keep an accurate timestamp.'
      break
    case 'timer':
      taskNamePlaceholder = 'Run 5k'
      taskDescriptionPlaceholder =
        'On a treadmill with no incline, time your 5k run. Hit start at the beginning and end to submit the final time.'
      break
    case 'questionnaire':
      taskNamePlaceholder = 'Baseline Questionnaire'
      taskDescriptionPlaceholder =
        'Thank you for being a part of this research. This questionnaire will help us understand your waking habits.'
      break
    case 'stopwatchGarminStream':
      taskNamePlaceholder = 'Golf Swing Practice'
      taskDescriptionPlaceholder = 'Acc text'
      break
    case 'stopwatchMovesenseStream':
    case 'stopwatchMovesenseLogData':
      taskNamePlaceholder = 'Tremor Test'
      taskDescriptionPlaceholder =
        'Using the attached wristband accessory, wear the Movesense device on your right wrist. Make sure the fit is snug enough that any movement of the wrist will not shake or move the Movesense device. Press start once the fit is correct, and measure for 30 minutes.'
      break
  }
  return {taskNamePlaceholder, taskDescriptionPlaceholder}
}

const schema = v.object({
  name: v.string().max(50).required(),
  description: v.string().max(250).required(),
  scheduleRepeat: v.string().optional(),
  scheduleDescription: v.string().max(50).required(),
})

export interface AddTaskPageProps {
  isEditing: EditingTask
  taskType:
    | 'todo'
    | 'questionnaire'
    | 'timer'
    | 'garminDevice'
    | 'stopwatchMovesenseStream'
    | 'stopwatchGarminStream'
    | 'dexcom'
  closePopup: () => void
  setAutoAddTaskType: Dispatch<SetStateAction<TaskTypeForInstructionPage>>
  setDisplayChangePlanPage: Dispatch<SetStateAction<boolean>>
}

export const AddTaskPage = (props: AddTaskPageProps) => {
  const {color, fontWeight, pad, colorTranslator} = selectTheme()

  const {
    doREQUEST_TASK_DELETE,
    doREQUEST_TASK_QUESTIONNAIRE_CREATE,
    doREQUEST_TASK_QUESTIONNAIRE_UPDATE,
    doREQUEST_TASK_TODO_CREATE,
    doREQUEST_TASK_TODO_UPDATE,
    doREQUEST_TASK_TIMER_CREATE,
    doREQUEST_TASK_TIMER_UPDATE,
    doQUESTIONNAIRES_SET,
    doQUESTIONNAIRES_BACKUP_SET,
    doQUESTIONNAIRES_RESTORE,
    doQUESTIONNAIRES_DELETE,
    doQUESTIONNAIRES_EDITING_STATUS_SET,
    doQUESTIONNAIRES_QUESTION_SET,
    doQUESTIONNAIRES_SECTION_SET,
    doREQUEST_TASK_STOPWATCH_MOVESENSE_STREAM_CREATE,
    doREQUEST_TASK_STOPWATCH_MOVESENSE_STREAM_UPDATE,
    doREQUEST_TASK_STOPWATCH_GARMIN_STREAM_CREATE,
    doREQUEST_TASK_STOPWATCH_GARMIN_STREAM_UPDATE,
    doREQUEST_TASK_UPDATE,
    doTEMP_TASK_DATA_UPDATE_TASK,
    doTEMP_TASK_DATA_ADD_TASK,
    doTEMP_TASK_DATA_DELETE_TASK,
    doTEMP_TASK_DATA_TASK_LIST_UPDATE,
  }: any = createDispatchActions()

  const {isEditing, taskType, closePopup, setAutoAddTaskType, setDisplayChangePlanPage} = props

  /* ------------------ basic state ------------------ */
  const { projectId, project } = useCurrentProjectState()
  const methodState = selectMethod()
  const methodId: string | null = methodState?.id
  const { workspaceId } = useCurrentWorkspaceState()
  const collaboratorType = useCollaboratorTypeInProject()
  const canCreateTask = collaboratorTypeHasRole({
    collaboratorType,
    projectRole: ProjectCollaboratorRole.TaskCreate,
    workspaceRole: WorkspaceCollaboratorRole.TaskCreate,
  })
  const canDeleteTask = collaboratorTypeHasRole({
    collaboratorType,
    projectRole: ProjectCollaboratorRole.TaskDelete,
    workspaceRole: WorkspaceCollaboratorRole.TaskDelete,
  })

  const isEditingLiveProject = project?.status === 'live'
  const tempTaskData = selectTempTaskData()
  const tempTaskList = tempTaskData?.taskList?.filter((item: TaskTempStateType) => item.type !== TaskType.GarminDevice) || []

  const [nameLength, setNameLength] = useState(0)
  const [descriptionLength, setDescriptionLength] = useState(0)
  const [scheduleDescriptionLength, setScheduleDescriptionLength] = useState(0)
  const [taskColor, setTaskColor] = useState<string>(isEditing?.id ? `#${isEditing.color}` : colorTranslator(0))

  const [reminderList, setReminderList] = useState<TaskReminder[]>(isEditing?.reminderList || [])

  const [showPopupConfirmDeleteTask, setShowPopupConfirmDeleteTask] = useState(false)
  const [showPopupConfirmDiscardTask, setShowPopupConfirmDiscardTask] = useState(false)
  const [showPopupConfirmShowTask, setShowPopupConfirmShowTask] = useState(false)
  const [formHasData, setFormHasData] = useState(false)
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true)

  const [showAddTaskBackIntoAppTooltip, setShowAddTaskBackIntoAppTooltip] = useState(false)
  const [showRemoveButtonPopup, setShowRemoveButtonPopup] = useState(false)
  const [taskScheduleReadonly, setTaskScheduleReadonly] = useState(false)

  // QN
  const questionnairesState = selectQuestionnaires()
  const [editingQuestionnaireId, setEditingQuestionnaireId] = useState('questionnaireDraft')
  const [questionnaireTitle, setQuestionnaireTitle] = useState('New Questionnaire')
  const [editQuestionnaireButtonDisabled, setEditQuestionnaireButtonDisabled] = useState(false)
  const [renderLiveEditQuestionnaireTooltip, setRenderLiveEditQuestionnaireTooltip] = useState(false)
  // const [questionnaireDraft, setQuestionnaireDraft] = useState<Questionnaire|undefined>()
  const [editingQuestionnaireInState, setEditingQuestionnaireInState] = useState<Questionnaire | undefined>()

  // useEffect(() => {
  //   const findQuestionnaireDraftResult = _.find(
  //     questionnairesState.questionnaireList,
  //     ['id', 'questionnaireDraft'],
  //   )
  //   setQuestionnaireDraft(findQuestionnaireDraftResult)
  // }, [questionnairesState.questionnaireList])

  useEffect(() => {
    if (!editingQuestionnaireId) return
    const findEditingQuestionnaireResult = _.find(questionnairesState.questionnaireList, ['id', editingQuestionnaireId])
    setEditingQuestionnaireInState(findEditingQuestionnaireResult)
  }, [editingQuestionnaireId, questionnairesState.questionnaireList])

  // movesense
  const movesenseStreamTask: TaskStateType | undefined = _.find(methodState?.taskList, {
    id: isEditing?.id,
  })
  const movesenseStreamTaskDetail: TaskStopwatchMovesenseStreamT | undefined | null =
    movesenseStreamTask?.stopwatchMovesenseStream
  const tempMovesenseStreamTask: TaskTempStateType | undefined = _.find(tempTaskData?.taskList, {
    id: isEditing?.id,
  })
  const tempMovesenseStreamTaskDetail: TaskStopwatchMovesenseStreamT | undefined | null =
    tempMovesenseStreamTask?.stopwatchMovesenseStream

  const parseMovesenseStreamSpec = (
    taskDetail: TaskStopwatchMovesenseStreamT | undefined | null,
  ): TaskStopwatchMovesenseStreamCopyT => {
    return {
      imuEnable: taskDetail?.imuEnable ?? false,
      imuHz: taskDetail?.imuHz ?? 13,
      accEnable: taskDetail?.accEnable ?? false,
      accHz: taskDetail?.accHz ?? 13,
      gyroscopeEnable: taskDetail?.gyroscopeEnable ?? false,
      gyroscopeHz: taskDetail?.gyroscopeHz ?? 13,
      magnetometerEnable: taskDetail?.magnetometerEnable ?? false,
      magnetometerHz: taskDetail?.magnetometerHz ?? 13,
      ecgEnable: taskDetail?.ecgEnable ?? false,
      ecgHz: taskDetail?.ecgHz ?? 125,
      rrEnable: taskDetail?.rrEnable ?? false,
      timeLimitSeconds: taskDetail?.timeLimitSeconds ?? 300,
    }
  }

  const [movesenseStreamEnable, setMovesenseStreamEnable] = useState(true)
  const [movesenseStreamTaskCopy, setMovesenseStreamTaskCopy] = useState<TaskStopwatchMovesenseStreamCopyT>(
    isEditingLiveProject
      ? parseMovesenseStreamSpec(tempMovesenseStreamTaskDetail)
      : parseMovesenseStreamSpec(movesenseStreamTaskDetail),
  )
  const [movesenseReadOnly, setMovesenseReadOnly] = useState(false)

  const {register, control, handleSubmit, watch, formState} = useForm({
    schema,
    mode: 'onChange',
  })

  // garmin stream
  const garminStreamTask: TaskStateType | undefined = _.find(methodState?.taskList, {
    id: isEditing?.id,
  })
  const garminStreamTaskDetail: TaskStopwatchGarminStreamT | undefined | null = garminStreamTask?.stopwatchGarminStream
  const tempGarminStreamTask: TaskTempStateType | undefined = _.find(tempTaskData?.taskList, {
    id: isEditing?.id,
  })
  const tempGarminStreamTaskDetail: TaskStopwatchGarminStreamT | undefined | null =
    tempGarminStreamTask?.stopwatchGarminStream

  const parseGarminStreamSpec = (
    taskDetail: TaskStopwatchGarminStreamT | undefined | null,
  ): TaskStopwatchGarminStreamCopyT => {
    return {
      accEnable: taskDetail?.accEnable ?? true,
      accHz: taskDetail?.accHz ?? 25,
      timeLimitSeconds: taskDetail?.timeLimitSeconds ?? 300,
    }
  }

  const [garminStreamEnable, setGarminStreamEnable] = useState(true)
  const [garminStreamTaskCopy, setGarminStreamTaskCopy] = useState<TaskStopwatchGarminStreamCopyT>(
    isEditingLiveProject
      ? parseGarminStreamSpec(tempGarminStreamTaskDetail)
      : parseGarminStreamSpec(garminStreamTaskDetail),
  )
  const [garminStreamReadOnly, setGarminStreamReadOnly] = useState(false)

  /* ------------------ default effect ------------------ */
  useEffect(() => {
    if (!canCreateTask) {
      setTaskScheduleReadonly(true)
      setMovesenseReadOnly(true)
      setGarminStreamReadOnly(true)
      return
    }
    if (!isEditing?.existed) {
      setTaskScheduleReadonly(false)
      setMovesenseReadOnly(false)
      setGarminStreamReadOnly(false)
      return
    }
    if (!isEditingLiveProject) {
      setTaskScheduleReadonly(false)
      setMovesenseReadOnly(false)
      setGarminStreamReadOnly(false)
      return
    }
    setTaskScheduleReadonly(true)
    setMovesenseReadOnly(true)
    setGarminStreamReadOnly(true)
  }, [isEditing])

  // input
  useEffect(() => {
    setNameLength(watch('name')?.length)
    setDescriptionLength(watch('description')?.length)
    setScheduleDescriptionLength(watch('scheduleDescription')?.length)
    if (!watch('name') && !watch('description') && !watch('scheduleDescription')) {
      setFormHasData(false)
    } else {
      setFormHasData(true)
    }
  }, [watch()])

  const closePopupAction = () => {
    if (!closePopup) return
    closePopup()
    setAutoAddTaskType('')
    if (taskType === 'questionnaire') doQUESTIONNAIRES_DELETE({id: 'questionnaireDraft'})
  }

  /* ------------------ QN effect ------------------ */
  useEffect(() => {
    let result = false
    if (isEditingLiveProject && isEditing.existed) {
      result = true
    }
    setEditQuestionnaireButtonDisabled(result)
  }, [isEditing])

  useEffect(() => {
    if (taskType === 'questionnaire') {
      let sectionId
      if (editingQuestionnaireInState?.sectionList?.length) {
        if (editingQuestionnaireInState.sectionList[0].legend)
          return setQuestionnaireTitle(editingQuestionnaireInState.sectionList[0].legend)
        sectionId = editingQuestionnaireInState.sectionList[0]
      }
      const findSectionResult = _.find(questionnairesState.sectionList, ['id', sectionId])
      setQuestionnaireTitle(findSectionResult?.legend || 'New Questionnaire')
    }
  }, [editingQuestionnaireInState])

  useEffect(() => {
    if (!isEditing?.questionnaire?.id) return
    setEditingQuestionnaireId(isEditing.questionnaire?.id)
    if (editingQuestionnaireInState) return
    const editingQuestionnaireCopy = JSON.parse(JSON.stringify(isEditing.questionnaire))
    const contentList: ContentListItem[] = []
    editingQuestionnaireCopy.sectionList.map((section: Section) => {
      section.id = t.uuid()
      section.questionnaireId = isEditing.id
      contentList.push({id: section.id, category: ContentListItemCategory.Section})
      section.questionList?.map((question: Question) => {
        question.sectionId = section.id
        question.id = t.uuid()
        contentList.push({
          id: question.id,
          sectionId: section.id,
          category: ContentListItemCategory.Question,
        })
        doQUESTIONNAIRES_QUESTION_SET(question)
      })
      doQUESTIONNAIRES_SECTION_SET(section)
    })
    doQUESTIONNAIRES_SET({
      ...editingQuestionnaireCopy,
      methodId: isEditing.methodId,
      contentList: contentList,
    })
  }, [isEditing])

  const createQuestionnaireDraft = () => {
    doQUESTIONNAIRES_BACKUP_SET({
      questionnaireList: questionnairesState.questionnaireList,
      sectionList: questionnairesState.sectionList,
      questionList: questionnairesState.questionList,
    })
    const sectionId = t.uuid()
    const questionnaireId = 'questionnaireDraft'
    const newSection = {
      questionnaireId: questionnaireId,
      id: sectionId,
      legend: 'New Section Title',
      index: 0,
      description: 'Description goes here',
      questionList: [],
    }

    const newQuestionnaire = {
      methodId,
      id: questionnaireId,
      taskId: 'taskDraft',
      projectId,
      workspaceId: isEditing.workspaceId,
      name: watch('name') ? watch('name') : 'Untitled Questionnaire',
      description: watch('description') ? watch('description') : 'Description goes here',
      sectionList: [sectionId],
      contentList: [{id: newSection.id, category: 'section'}],
    }
    doQUESTIONNAIRES_SECTION_SET(newSection)
    doQUESTIONNAIRES_SET(newQuestionnaire)
    doQUESTIONNAIRES_EDITING_STATUS_SET({
      editingQuestionnaireId: questionnaireId,
      editingBlock: newSection.id,
      viewOnly: false,
    })
  }

  const editQuestionnaire = () => {
    if (editQuestionnaireButtonDisabled) return
    if (!editingQuestionnaireInState?.name && watch('name')) {
      const editingQuestionnaireInStateCopy = JSON.parse(JSON.stringify(editingQuestionnaireInState))
      editingQuestionnaireInStateCopy.name = watch('name')
      doQUESTIONNAIRES_SET(editingQuestionnaireInStateCopy)
    }
    doQUESTIONNAIRES_BACKUP_SET({
      questionnaireList: questionnairesState.questionnaireList,
      sectionList: questionnairesState.sectionList,
      questionList: questionnairesState.questionList,
    })
    doQUESTIONNAIRES_EDITING_STATUS_SET({
      editingQuestionnaireId: editingQuestionnaireId ? editingQuestionnaireId : 'questionnaireDraft',
      viewOnly: !canCreateTask,
    })
  }

  /* ------------------ Movesense effect ------------------ */
  useEffect(() => {
    const result =
      movesenseStreamTaskCopy.imuEnable ||
      movesenseStreamTaskCopy.accEnable ||
      movesenseStreamTaskCopy.gyroscopeEnable ||
      movesenseStreamTaskCopy.magnetometerEnable ||
      movesenseStreamTaskCopy.ecgEnable ||
      movesenseStreamTaskCopy.rrEnable
    setMovesenseStreamEnable(result)
  }, [movesenseStreamTaskCopy])

  /* ------------------ garmin stream effect ------------------ */
  useEffect(() => {
    setGarminStreamEnable(garminStreamTaskCopy.accEnable)
  }, [garminStreamTaskCopy])

  /* ------------------ submit (TempTask create/update) ------------------ */
  const onSubmit: SubmitHandler<Record<string, string>> = (data) => {
    if (!isEditing.id) {
      if (!isEditingLiveProject) {
        createTask(data)
      } else {
        saveTempTaskToBeCreated(data)
      }
    } else {
      if (!isEditingLiveProject) {
        updateTask(data)
      } else {
        saveTempTaskToBeUpdated(data)
      }
    }
  }

  const onError = (error: any) => {
    // console.log('error', error)
  }

  /* ------------------------ request (Task, TempTask CRUD ) ------------------------ */
  const createTask = (data: Record<string, string>) => {
    const newTask = {
      name: data.name,
      methodId,
      scheduleRepeat: data.scheduleRepeat === 'repeat',
      scheduleDescription: data.scheduleDescription,
      reminderList,
      description: data.description || ' ',
      color: taskColor.substring(1),
    }
    switch (taskType) {
      case 'todo':
        doREQUEST_TASK_TODO_CREATE({
          payload: newTask,
        })
        break

      case 'timer':
        doREQUEST_TASK_TIMER_CREATE({
          payload: newTask,
        })
        break

      case 'questionnaire': {
        const sectionList = editingQuestionnaireInState?.sectionList
        doREQUEST_TASK_QUESTIONNAIRE_CREATE({
          payload: {
            ...newTask,
            sectionList: sectionList,
          },
        })
        break
      }
      case 'stopwatchMovesenseStream': {
        doREQUEST_TASK_STOPWATCH_MOVESENSE_STREAM_CREATE({
          payload: {
            ...newTask,
            ...movesenseStreamTaskCopy,
          },
        })
        break
      }
      case 'stopwatchGarminStream': {
        doREQUEST_TASK_STOPWATCH_GARMIN_STREAM_CREATE({
          payload: {
            ...newTask,
            ...garminStreamTaskCopy,
          },
        })
        break
      }
    }
    closePopupAction()
  }

  const updateTask = (data: Record<string, string>) => {
    switch (taskType) {
      case 'todo':
        doREQUEST_TASK_TODO_UPDATE({
          payload: {
            ...data,
            reminderList,
            scheduleRepeat: data.scheduleRepeat === 'repeat',
            description: data.description || ' ',
            taskId: isEditing.id,
            color: taskColor.substring(1),
          },
        })
        break
      case 'timer':
        doREQUEST_TASK_TIMER_UPDATE({
          payload: {
            ...data,
            reminderList,
            scheduleRepeat: data.scheduleRepeat === 'repeat',
            description: data.description || ' ',
            taskId: isEditing.id,
            color: taskColor.substring(1),
          },
        })
        break
      case 'questionnaire': {
        const questionnaire = _.find(questionnairesState.questionnaireList, ['id', editingQuestionnaireId])
        if (!questionnaire) return
        delete questionnaire.methodId
        delete questionnaire.contentList
        questionnaire.sectionList?.map((section: Section) => {
          section.questionList?.map((question: Question) => {
            delete question.sectionId
          })
        })

        doREQUEST_TASK_QUESTIONNAIRE_UPDATE({
          payload: {
            id: isEditing.id,
            projectId: isEditing.projectId,
            workspaceId: isEditing.workspaceId,
            methodId: isEditing.workspaceId,
            type: TaskType.Questionnaire,
            scheduleDescription: data.scheduleDescription,
            scheduleRepeat: data.scheduleRepeat === 'repeat',
            reminderList,
            questionnaire: {
              ...questionnaire,
              name: data.name,
              description: data.description,
            },
            color: taskColor.substring(1),
          },
        })
        break
      }
      case 'stopwatchMovesenseStream':
        doREQUEST_TASK_STOPWATCH_MOVESENSE_STREAM_UPDATE({
          payload: {
            ...data,
            reminderList,
            scheduleRepeat: data.scheduleRepeat === 'repeat',
            description: data.description || ' ',
            taskId: isEditing.id,
            color: taskColor.substring(1),
            ...movesenseStreamTaskCopy,
          },
        })
        break
      case 'stopwatchGarminStream':
        doREQUEST_TASK_STOPWATCH_GARMIN_STREAM_UPDATE({
          payload: {
            ...data,
            reminderList,
            scheduleRepeat: data.scheduleRepeat === 'repeat',
            description: data.description || ' ',
            taskId: isEditing.id,
            color: taskColor.substring(1),
            ...garminStreamTaskCopy,
          },
        })
        break
    }
    closePopupAction()
  }

  const saveTempTaskToBeCreated = (data: Record<string, string>) => {
    const tempTaskId = t.uuid()
    const tempTask: any = {
      id: tempTaskId,
      workspaceId,
      projectId,
      methodId,
      actionType: 'create',
      existed: false,
      scheduleRepeat: data.scheduleRepeat === 'repeat',
      scheduleDescription: data.scheduleDescription,
      reminderList,
      color: taskColor.substring(1),
      enabled: true,
      type: camelToSnake(taskType),
      [taskType]: {
        name: data.name,
        description: data.description,
        projectId,
        taskId: tempTaskId,
      },
    }
    switch (taskType) {
      case 'questionnaire': {
        const tempQuestionnaireId = t.uuid()
        if (!editingQuestionnaireInState?.sectionList) break
        const sectionList = [...editingQuestionnaireInState.sectionList]
        tempTask.questionnaire.id = tempQuestionnaireId
        tempTask.questionnaire.sectionList = sectionList
        break
      }
      case 'stopwatchMovesenseStream':
        tempTask.stopwatchMovesenseStream = {
          ...tempTask.stopwatchMovesenseStream,
          ...movesenseStreamTaskCopy,
        }
        break
      case 'stopwatchGarminStream':
        tempTask.stopwatchGarminStream = {
          ...tempTask.stopwatchGarminStream,
          ...garminStreamTaskCopy,
        }
        break
    }
    // if (taskType === 'questionnaire') {
    //   const tempQuestionnaireId = t.uuid()
    //   const sectionList = questionnairesState['questionnaireDraft'].sectionList
    //   tempTask.questionnaire.id = tempQuestionnaireId
    //   tempTask.questionnaire.sectionList = sectionList
    // }
    // if (taskType === 'stopwatchMovesenseStream') {
    //   tempTask.stopwatchMovesenseStream = {
    //     ...tempTask.stopwatchMovesenseStream,
    //     ...movesenseStreamTaskCopy,
    //   }
    // }
    // if (taskType === 'stopwatchGarminStream') {
    //   tempTask.stopwatchGarminStream = {
    //     ...tempTask.stopwatchGarminStream,
    //     ...garminStreamTaskCopy,
    //   }
    // }
    doTEMP_TASK_DATA_ADD_TASK(tempTask)
    closePopupAction()
  }

  const saveTempTaskToBeUpdated = (data: Record<string, string>) => {
    const tempTask = {
      id: isEditing.id,
      workspaceId,
      projectId,
      methodId,
      actionType: isEditing.existed ? 'update' : 'create',
      existed: isEditing.existed,
      index: isEditing.index,
      scheduleRepeat: data.scheduleRepeat === 'repeat',
      scheduleDescription: data.scheduleDescription,
      reminderList,
      color: taskColor.substring(1),
      type: camelToSnake(taskType),
      enabled: true,
      [taskType]: {
        name: data.name,
        description: data.description,
        projectId,
        taskId: isEditing.id,
      },
    }
    switch (taskType) {
      case 'questionnaire': {
        const questionnaireInState = _.find(questionnairesState.questionnaireList, ['id', editingQuestionnaireId])
        const questionnaire = {
          ...questionnaireInState,
          name: data.name,
          description: data.description,
        }
        delete questionnaire.methodId
        delete questionnaire.contentList
        questionnaire.sectionList?.map((section: Section) => {
          delete section.id
          delete section.questionnaireId
          section.questionList?.map((question: Question) => {
            delete question.id
            delete question.sectionId
          })
        })
        tempTask.questionnaire = questionnaire
        break
      }
      case 'stopwatchMovesenseStream':
        tempTask.stopwatchMovesenseStream = {
          ...tempTask.stopwatchMovesenseStream,
          ...movesenseStreamTaskCopy,
        }
        break
      case 'stopwatchGarminStream':
        tempTask.stopwatchGarminStream = {
          ...tempTask.stopwatchGarminStream,
          ...garminStreamTaskCopy,
        }
        break
    }

    // if (taskType === 'questionnaire') {
    //   const questionnaire = {
    //     ...questionnairesState[editingQuestionnaireId],
    //     name: data.name,
    //     description: data.description,
    //   }
    //   delete questionnaire.methodId
    //   delete questionnaire.contentList
    //   questionnaire.sectionList.map((section: any) => {
    //     section.questionList.map((question: any) => {
    //       delete question.sectionId
    //     })
    //   })
    //   tempTask.questionnaire = questionnaire
    // }
    // if (taskType === 'stopwatchMovesenseStream') {
    //   tempTask.stopwatchMovesenseStream = {
    //     ...tempTask.stopwatchMovesenseStream,
    //     ...movesenseStreamTaskCopy,
    //   }
    // }
    // if (taskType === 'stopwatchGarminStream') {
    //   tempTask.stopwatchGarminStream = {
    //     ...tempTask.stopwatchGarminStream,
    //     ...garminStreamTaskCopy,
    //   }
    // }

    doTEMP_TASK_DATA_UPDATE_TASK(tempTask)
    closePopupAction()
  }

  const deleteTask = () => {
    if (!isEditingLiveProject) {
      doREQUEST_TASK_DELETE({
        payload: {
          taskId: isEditing.id,
        },
      })
      setShowPopupConfirmDeleteTask(false)
    } else {
      const removingTask = {
        ...tempTaskList.find((task: any) => task.id === isEditing.id),
      }
      // hide task if it's already exist in backend
      if (removingTask.existed) {
        removingTask.enabled = false
        removingTask.actionType = 'hide'
        doTEMP_TASK_DATA_UPDATE_TASK(removingTask)
      } else {
        // or if not, remove it
        doTEMP_TASK_DATA_DELETE_TASK({
          taskId: isEditing.id,
        })
      }
    }
    closePopupAction()
  }

  /* ------------------ other effect ------------------ */
  const handleDiscard = () => {
    if (formHasData) {
      setShowPopupConfirmDiscardTask(true)
    } else {
      closePopupAction()
    }
  }

  const handleButtonCancelClick = () => {
    if (isEditing.id) {
      if (formState.isDirty) {
        setShowPopupConfirmDiscardTask(true)
      } else {
        closePopupAction()
      }
    } else {
      if (formHasData) {
        setShowPopupConfirmDiscardTask(true)
      } else {
        closePopupAction()
      }
    }
  }

  const handleShowHiddenTask = () => {
    if (!isEditingLiveProject) {
      doREQUEST_TASK_UPDATE({
        payload: {
          taskId: isEditing.id,
          enabled: true,
        },
      })
    } else {
      const newTaskList: TaskTempStateType[] = JSON.parse(JSON.stringify(tempTaskList))
      const targetIndex: number = newTaskList.findIndex((task) => task.id === isEditing.id)
      const findTargetTask = _.find(newTaskList, {id: isEditing?.id})
      const targetTask: TaskTempStateType | undefined = findTargetTask && {
        ...findTargetTask,
        enabled: true,
        actionType: 'show',
        color: taskColor.substring(1),
      }
      if (targetTask) {
        const taskContentName = getTaskContentName(targetTask?.type)
        targetTask[taskContentName].name = watch('name')
        targetTask[taskContentName].description = watch('description')
        if (targetTask.type === TaskType.StopwatchMovesenseStream) {
          targetTask[taskContentName] = {
            ...targetTask[taskContentName],
            ...movesenseStreamTaskCopy,
          }
        }
        if (targetTask.type === TaskType.StopwatchGarminStream) {
          targetTask[taskContentName] = {
            ...targetTask[taskContentName],
            ...garminStreamTaskCopy,
          }
        }
        newTaskList.splice(targetIndex, 1)
        newTaskList.push(targetTask)
        doTEMP_TASK_DATA_TASK_LIST_UPDATE({
          taskList: newTaskList,
        })
      }
    }
    closePopupAction()
  }

  // save Btn disable toggle
  useEffect(() => {
    if (!formState.isValid) return setSaveBtnDisabled(true)
    switch (taskType) {
      case 'questionnaire': {
        if (!editingQuestionnaireInState) return setSaveBtnDisabled(true)
        break
      }
      case 'stopwatchMovesenseStream':
        if (!movesenseStreamEnable) return setSaveBtnDisabled(true)
        break
      case 'stopwatchGarminStream':
        if (!garminStreamEnable) return setSaveBtnDisabled(true)
        break
    }
    setSaveBtnDisabled(!formState.isValid)
  }, [questionnairesState, formState, movesenseStreamEnable, garminStreamEnable])

  const taskTypeTranslator: Record<string, string> = {
    todo: 'To-Do Task',
    timer: 'Timer Task',
    questionnaire: 'Questionnaire Task',
    stopwatchMovesenseStream: 'Movesense',
    stopwatchGarminStream: 'ACC Streaming Task',
  }

  // handleEsc(handleButtonCancelClick)

  return (
    <>
      {/* page wrapper */}
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        data-testid='add_task_page'
        css={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          minHeight: '100%',
          // overflowY: 'scroll',
          background: color.background,
          zIndex: 102,
          marginTop: '30px',
          paddingYop: '50px',
        }}
      >
        {/* ------------------------- save bar ------------------------- */}
        <div
          css={{
            width: '100vw',
            height: '50px',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.13)',
            padding: `0 ${pad.large}`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'fixed',
            top: '0',
            left: '0',
            backgroundColor: color.white,
            zIndex: 101,
          }}
        >
          <ButtonCancel data-testid='add_task_page_cancel_btn' onClick={handleButtonCancelClick} />

          {RIF(
            !isEditing.id || isEditing?.enabled,
            <div css={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
              {RIF(
                isEditing.id,
                <div
                  onMouseEnter={() => isEditingLiveProject && setShowRemoveButtonPopup(true)}
                  onMouseLeave={() => isEditingLiveProject && setShowRemoveButtonPopup(false)}
                  css={{position: 'relative'}}
                >
                  {RIF(
                    canDeleteTask,
                    <TextLink
                      type='button'
                      onClick={() => setShowPopupConfirmDeleteTask(true)}
                      css={{color: color.attention, marginRight: '20px'}}
                      children={isEditingLiveProject ? 'Hide' : 'Delete'}
                    />,
                  )}
                  {RIF(
                    showRemoveButtonPopup,
                    <div
                      css={{
                        position: 'absolute',
                        left: '-80px',
                        top: '32px',
                        width: '221px',
                        borderRadius: '5px',
                        padding: '10px 8px',
                        textAlign: 'center',
                        background: color.black,
                        color: color.white,
                      }}
                    >
                      Remove this task from participant's task list. <br />
                      This action cannot be undone!
                    </div>,
                  )}
                </div>,
              )}
              {RIF(
                canCreateTask && !isEditing.id,
                <TextLink
                  type='button'
                  onClick={handleDiscard}
                  css={{color: color.attention, marginRight: '40px'}}
                  children='Discard'
                />,
              )}
              {RIF(
                canCreateTask,
                <Button
                  data-testid='add_task_page_save_btn'
                  disabled={saveBtnDisabled}
                  type='submit'
                  btnPadding='medium'
                >
                  Save
                </Button>,
              )}
            </div>,
          )}

          {RIF(
            !!isEditing?.id && !isEditing?.enabled,
            <div css={{display: 'flex', alignItems: 'center', justifyContent: 'end', position: 'relative'}}>
              {RIF(
                showAddTaskBackIntoAppTooltip,
                <div
                  css={{
                    width: '221px',
                    padding: '10px 8px',
                    textAlign: 'center',
                    backgroundColor: color.black,
                    color: color.white,
                    position: 'absolute',
                    top: '40px',
                    right: '0',
                    borderRadius: '5px',
                  }}
                >
                  Showing this task will mean participants will see this task in their app (after you save and update
                  live changes).
                </div>,
              )}
              <Button
                css={{width: '205px'}}
                btnColor='warning'
                btnPadding='medium'
                type='button'
                onClick={() => setShowPopupConfirmShowTask(true)}
                onMouseEnter={() => setShowAddTaskBackIntoAppTooltip(true)}
                onMouseLeave={() => setShowAddTaskBackIntoAppTooltip(false)}
              >
                Add task back into the app
              </Button>
            </div>,
          )}
        </div>

        {/* ------------------------- page container ------------------------- */}
        <div css={{display: 'flex'}}>
          {/* -------------- left section------------- */}
          <section
            css={{
              width: '26%',
              // height: '100%',
              height: '100vh',
              overflowY: 'scroll',
              padding: '40px 50px 0 80px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'column',
              borderRight: `1px solid ${color.grey_100}`,
              '::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {/* left top */}
            <div>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '30px',
                  lineHeight: '1.857rem',
                }}
              >
                <div
                  css={{
                    height: '26px',
                    width: '26px',
                    marginRight: pad.mediumSmall,
                    display: 'inline-block',
                    marginBottom: taskType === 'stopwatchMovesenseStream' ? '38px' : '8px',
                  }}
                >
                  {RIF(taskType === 'todo', <img src={TodoBlackIcon} width='26' />)}
                  {RIF(taskType === 'timer', <img src={TimerBlackIcon} width='26' />)}
                  {RIF(taskType === 'questionnaire', <img src={SurveyBlackIcon} width='26' />)}
                  {RIF(
                    taskType === 'stopwatchMovesenseStream',
                    <img src={MovesenseImg} width='56' css={{transform: 'scale(1.2)'}} />,
                  )}
                  {RIF(taskType === 'stopwatchGarminStream', <img src={DeviceIcon} width='26' />)}
                </div>
                <div
                  css={{
                    fontSize: '20px',
                    fontWeight: fontWeight.thick,
                  }}
                >
                  {isEditing.id ? 'Edit' : 'Add'} {taskTypeTranslator[taskType]}
                </div>
              </div>

              {RIF(
                taskType === 'todo',
                <div
                  css={{
                    fontWeight: fontWeight.regular,
                    marginTop: '20px',
                  }}
                >
                  To-Do Tasks are simple tasks or activities that you would like the participants to complete.
                  <br />
                  <br />
                  They can be one time only or repeatable throughout the duration of the project.
                  <br />
                  <br />
                  Example: Do 10-minutes of meditation each morning.
                </div>,
              )}

              {RIF(
                taskType === 'timer',
                <div
                  css={{
                    fontWeight: fontWeight.regular,
                    marginTop: '20px',
                  }}
                >
                  A Timer Task is a timed task for the participants to complete.
                  <br />
                  <br />
                  Example: Complete a 20-minute yoga session.
                </div>,
              )}

              {RIF(
                taskType === 'questionnaire',
                <div
                  css={{
                    fontWeight: fontWeight.regular,
                    marginTop: '20px',
                  }}
                >
                  A Questionnaire Task is useful if you need your participants to complete one or more questionnaires
                  during the project.
                  <br />
                  <br />
                  Example: Complete a short mood questionnaire.
                </div>,
              )}

              {RIF(
                taskType === 'stopwatchMovesenseStream',
                <div
                  css={{
                    fontWeight: fontWeight.regular,
                    marginTop: '20px',
                  }}
                >
                  This device is generally used for two purposes (which also can be combined). IMU movement sensor
                  and/or ECG heart monitor.
                  <br />
                  <br />
                  To use the <span css={{fontWeight: '700'}}>movement</span> function, first decide where you want to
                  track the movement. Then turn on the subsequent settings. Ex: Wear on wrist to track hand tremors.
                  <br />
                  <br />
                  To use the <span css={{fontWeight: '700'}}>heart rate</span> function (used for things like heart rate
                  variability) use it with the chest band accessory or chest patch. Make sure to turn on the ECG or RR
                  data type collection.
                  <br />
                  <br />
                  To use both functions combined, remember that you must wear the device either using the chest strap or
                  patch. Then, just remember to turn on the IMU data settings.
                  <br />
                  <br />
                  For more examples, visit our{' '}
                  <a
                    href='https://help.labfront.com/movesense-examples'
                    target='_blank'
                    css={{
                      fontWeight: '700',
                      color: color.primary,
                    }}
                  >
                    Help Center
                  </a>
                </div>,
              )}

              {RIF(
                taskType === 'stopwatchGarminStream',
                <div
                  css={{
                    fontWeight: fontWeight.regular,
                    marginTop: '20px',
                  }}
                >
                  This is a Device Streaming Task to capture accelerometer data from your Garmin device.
                  <br />
                  <br />
                  <p css={{fontWeight: '600'}}>Important Things to Consider:</p>
                  <br />
                  1. Due to the overwhelming amount of data captured by this sensor, participants will need to manually
                  start and stop the data collection.
                  <br />
                  <br />
                  2. Tasks are limited to 12 hours in duration.
                  <br />
                  <br />
                  3.Unlike continuous data collection, streaming data is NOT stored on the Garmin device. Therefore,
                  participants must have their Bluetooth turned on and keep their phone near their device to record
                  data.
                  <br />
                  <br />
                  For more information, please check out our{' '}
                  <a href='https://help.labfront.com/garmin-acc-streaming' css={{color: color.primary}}>
                    Help Center
                  </a>{' '}
                  article.
                </div>,
              )}
            </div>
          </section>

          {/* -------------- middle section------------- */}
          <section
            css={{
              width: '48%',
              // minHeight: '100%',
              height: '100vh',
              overflowY: 'scroll',
              background: color.background,
              padding: '80px 80px 30px 50px',
            }}
          >
            {/* Name */}
            <div>
              <LabelBox
                {...{
                  label: 'Name',
                  required: true,
                  leftChracters: isNaN(50 - nameLength) ? 50 : 50 - nameLength,
                }}
              >
                <Input
                  {...register('name', {required: true})}
                  autoComplete='off'
                  value={isEditing[taskType]?.name}
                  maxLength='50'
                  readOnly={!canCreateTask}
                  hasError={formState.errors.name ? true : false}
                  placeholder={taskPlaceholder(taskType).taskNamePlaceholder}
                />
              </LabelBox>
            </div>

            {/* Description */}
            <div css={{marginTop: '40px'}}>
              <LabelBox
                {...{
                  label: 'Description',
                  required: true,
                  leftChracters: isNaN(250 - descriptionLength) ? 250 : 250 - descriptionLength,
                }}
              >
                <Controller
                  control={control}
                  name='description'
                  defaultValue={isEditing[taskType]?.description}
                  render={({field: {name, value, onChange}}) => (
                    <Textarea
                      {...{
                        name: name,
                        value: value,
                        onChange: onChange,
                      }}
                      placeholder={taskPlaceholder(taskType).taskDescriptionPlaceholder}
                      rows='6'
                      maxLength='250'
                      readOnly={!canCreateTask}
                      hasError={formState.errors.description ? true : false}
                    />
                  )}
                />
              </LabelBox>
            </div>

            {/* Color */}
            <div css={{margin: '40px 0'}}>
              <LabelBox {...{label: 'Color', required: true}}>
                <ColorSelector
                  {...{
                    taskColor,
                    setTaskColor,
                  }}
                />
              </LabelBox>
            </div>

            {/* Questionnaire */}
            {RIF(
              taskType === 'questionnaire',
              <div css={{paddingTop: '40px', marginBottom: '40px', borderTop: `1px solid ${color.grey_100}`}}>
                <div css={{display: 'flex', alignItems: 'center'}}>
                  <img src={SurveyBlackIcon} width={20} height={20} />
                  <Label css={{margin: '0 8px'}}>Questionnaire</Label>
                  <RequiredTag />
                </div>
                {RIF(
                  !editingQuestionnaireInState,
                  <TextLink
                    onClick={createQuestionnaireDraft}
                    type='button'
                    css={{
                      marginBottom: pad.medium,
                      marginTop: '15px',
                      width: '100%',
                      height: '42px',
                      border: `1px dashed ${color.primary}`,
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: color.primary,
                      fontWeight: fontWeight.bold,
                      cursor: 'pointer',
                    }}
                  >
                    + Create New Questionnaire
                  </TextLink>,
                )}
                {RIF(
                  editingQuestionnaireInState,
                  <div
                    onMouseEnter={() => {
                      if (
                        isEditingLiveProject &&
                        !questionnairesState.duplicatedQuestionnaireList.includes(editingQuestionnaireId)
                      )
                        setRenderLiveEditQuestionnaireTooltip(true)
                    }}
                    onMouseLeave={() => {
                      if (
                        isEditingLiveProject &&
                        !questionnairesState.duplicatedQuestionnaireList.includes(editingQuestionnaireId)
                      )
                        setRenderLiveEditQuestionnaireTooltip(false)
                    }}
                    css={{position: 'relative'}}
                  >
                    <TextLink
                      disabled={editQuestionnaireButtonDisabled}
                      onClick={editQuestionnaire}
                      type='button'
                      css={{
                        marginBottom: pad.medium,
                        marginTop: '15px',
                        width: '100%',
                        height: '42px',
                        borderRadius: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: color.white,
                        padding: `${pad.mediumSmall} ${pad.slightlyLarger}`,
                        boxShadow: '0px 4px 12px rgba(213, 213, 213, 0.25)',
                        border: '1px solid',
                        borderColor: 'transparent',
                        ':hover': {
                          borderColor: color.grey_400,
                        },
                      }}
                    >
                      <p css={{fontWeight: fontWeight.bold}}>{questionnaireTitle}</p>
                      <p
                        css={{
                          fontWeight: fontWeight.bold,
                          color: color.primary,
                          opacity: editQuestionnaireButtonDisabled ? '.4' : '1',
                        }}
                        children={canCreateTask ? 'Edit' : 'View'}
                      />
                    </TextLink>
                    {RIF(
                      renderLiveEditQuestionnaireTooltip,
                      <div
                        css={{
                          position: 'absolute',
                          right: '-84px',
                          bottom: '40px',
                          width: '221px',
                          borderRadius: '5px',
                          padding: '10px 8px',
                          textAlign: 'center',
                          background: color.black,
                          color: color.white,
                        }}
                      >
                        Questionnaires cannot be edited while a project is in progress. <br />
                        If you wish to change a questionnaire, create a new task with a new questionnaire instead, then
                        hide this questionnaire task.
                      </div>,
                    )}
                  </div>,
                )}
              </div>,
            )}

            {/* Schedule */}
            <AddTaskScheduleSection
              {...{
                control,
                register,
                watch,
                formState,
                taskScheduleReadonly,
                setTaskScheduleReadonly,
                scheduleDescriptionLength,
                setScheduleDescriptionLength,
                isEditing,
              }}
            />

            {/* movesense */}
            {RIF(
              taskType === 'stopwatchMovesenseStream',
              <AddTaskMovesenseSection
                {...{
                  movesenseStreamTaskCopy,
                  setMovesenseStreamTaskCopy,
                  movesenseReadOnly,
                  setDisplayChangePlanPage,
                }}
              />,
            )}

            {/* garmin stream */}
            {RIF(
              taskType === 'stopwatchGarminStream',
              <AddTaskGarminStreamSection
                {...{
                  garminStreamTaskCopy,
                  setGarminStreamTaskCopy,
                  garminStreamReadOnly,
                  setDisplayChangePlanPage,
                }}
              />,
            )}

            {/* Reminder */}
            <AddTaskReminderSection
              {...{
                reminderList,
                setReminderList,
              }}
            />
          </section>

          {/* -------------- right section------------- */}
          <section css={{width: '26%', background: color.grey_80}}>
            <AddTaskAppPreviewSection
              {...{
                watch,
                taskType,
                taskColor,
                reminderList,
              }}
            />
          </section>
        </div>
      </form>
      {RIF(
        showPopupConfirmDeleteTask,
        <PopupConfirmDeleteTask
          {...{
            taskName: isEditing[taskType]?.name,
            closeAction: setShowPopupConfirmDeleteTask,
            deleteAction: deleteTask,
          }}
        />,
      )}
      {RIF(
        showPopupConfirmDiscardTask,
        <PopupConfirmDiscardTask
          {...{
            closeAction: setShowPopupConfirmDiscardTask,
            discardAction: closePopupAction,
          }}
        />,
      )}
      {RIF(
        showPopupConfirmShowTask,
        <PopupConfirmShowTask
          {...{
            closeAction: setShowPopupConfirmShowTask,
            showAction: handleShowHiddenTask,
          }}
        />,
      )}
    </>
  )
}
