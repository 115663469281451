import {groupBy} from 'lodash'
import {
  CorrelationRequestVariablesId,
  CorrelationResponse,
  CorrelationResult,
  CorrelationResultAnalysisType,
  CorrelationResultCorrelationData,
  CorrelationResultData,
  CorrelationResultDistributionData,
  CorrelationResultTTestData,
  Variable,
} from '../../../shared/analysis'
import {CorrelationCoordinate} from './correlation_coordinate'
import ReactGridLayout, {WidthProvider} from 'react-grid-layout'
import {selectBatchData, selectTheme} from '../../../store'
import _ from 'lodash'
import {getVariableDisplayName} from '../../../lib/chart_data'
import {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react'
import {v4} from 'uuid'
import {RIF, useCurrentProjectState} from '../../../engine'
import {ParticipantStateType} from '../../../model'
import {DateObject} from 'react-multi-date-picker'
const ReactGridLayoutWidthProvider = WidthProvider(ReactGridLayout)

type DataWithCoord<T extends CorrelationResultData> = {
  data: T
  coord: CorrelationCoordinate
}

enum DataFrameTableItemType {
  Blank = 'blank',
  Header = 'header',
  Value = 'value',
}
interface DataFrameTableItem {
  dataGrid: {
    x: number
    y: number
    w: number
    h: number
    static: boolean
  }
  value: string
  type: DataFrameTableItemType
}

interface CorrelationDataFrameCorrelationCoord {
  individual: string | null
  group: string | null
  duration: string | null
}

// TODO: implement onCLickCell
const CorrelationDataFrameCorrelation = (props: {
  data: DataWithCoord<CorrelationResultCorrelationData>[]
  selected: CorrelationCoordinate | undefined
  onClickCell: (coord: CorrelationCoordinate) => void
  displayingVariableList: Variable[]
  duration: [DateObject, DateObject]
}) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {data, selected, onClickCell, displayingVariableList, duration} = props
  const {project} = useCurrentProjectState()
  const batchId: string | undefined = project?.batchList?.[0]?.id
  const participantList: ParticipantStateType[] = selectBatchData()?.[batchId ?? '']?.participantList || []
  const [participantInsigniaTable, setParticipantInsigniaTable] = useState<{[key: string]: string}>({})

  const defaultCoord = {individual: null, group: null, duration: null}
  const tableDefault: DataFrameTableItem = {
    dataGrid: {x: 0, y: 0, w: 1, h: 1, static: true},
    value: '',
    type: DataFrameTableItemType.Blank,
  }
  const [coord, setCoord] = useState<CorrelationDataFrameCorrelationCoord>(defaultCoord)
  const [table, setTable] = useState<DataFrameTableItem[]>([tableDefault])
  const [header, setHeader] = useState<DataFrameTableItem[]>([])
  useMemo(() => {
    if (!displayingVariableList.length) return setHeader([])
    const result = displayingVariableList.flatMap((variable, i) => [
      {
        dataGrid: {x: i + 1, y: 0, w: 1, h: 1, static: true},
        value: getVariableDisplayName(variable),
        type: DataFrameTableItemType.Header,
      },
      {
        dataGrid: {x: 0, y: i + 1, w: 1, h: 1, static: true},
        value: getVariableDisplayName(variable),
        type: DataFrameTableItemType.Header,
      },
    ])
    setHeader(result)
  }, [displayingVariableList])

  const getIndex = (str: string): number => {
    if (!str.length) return 0
    return str.charCodeAt(str.length - 1) - 'A'.charCodeAt(0) + 1
  }
  const [tabList, setTabList] = useState<CorrelationDataFrameCorrelationCoord[]>([])

  useMemo(() => {
    if (!data.length) return
    const durationList: Set<string> = new Set()
    const individualList: Set<string> = new Set()
    const groupList: Set<string> = new Set()
    data.forEach(({data}) => {
      const {duration, individual, group} = data.coord
      if (duration) durationList.add(duration)
      if (individual) {
        individualList.add(individual)
        const findResult = _.find(participantList, ['id', individual])
        setParticipantInsigniaTable((prev) => {
          return {
            ...prev,
            [individual]: findResult?.insignia ?? 'Unknown',
          }
        })
      }
      if (group) groupList.add(group)
    })
    const defaultCoord = {individual: null, group: null, duration: null}
    const tempTabList: CorrelationDataFrameCorrelationCoord[] = []
    if (durationList.size > 1) {
      // multiple duration
      if (individualList.size > 1) {
        // multiple individual
        for (const individual of individualList) {
          for (const duration of durationList) {
            tempTabList.push({...defaultCoord, individual, duration})
          }
        }
      } else if (groupList.size > 1) {
        // multiple group
        for (const group of groupList) {
          for (const duration of durationList) {
            tempTabList.push({...defaultCoord, group, duration})
          }
        }
      } else {
        // single individual or group
        for (const duration of durationList) {
          tempTabList.push({...defaultCoord, duration})
        }
      }
    } else {
      // single duration
      if (individualList.size > 1) {
        // multiple individual
        for (const individual of individualList) {
          tempTabList.push({...defaultCoord, individual})
        }
      } else if (groupList.size > 1) {
        // multiple group
        for (const group of groupList) {
          tempTabList.push({...defaultCoord, group})
        }
      }
    }
    if (!tempTabList.length) tempTabList.push(defaultCoord)
    setTabList(tempTabList)
    setCoord(tempTabList[0])
  }, [data])

  useMemo(() => {
    if (!data.length) return setTable([])
    const result = data
      .filter((d) => {
        return _.isEqual(d.data.coord, coord) || _.isEqual(coord, defaultCoord)
      })
      .map(({data, coord}) => ({
        dataGrid: {x: getIndex(coord.column), y: getIndex(coord.row), w: 1, h: 1, static: true},
        value: `${data.r_value?.toFixed(2)}`,
        type: DataFrameTableItemType.Value,
      }))
    setTable([tableDefault, ...header, ...result])
  }, [coord, data])

  return (
    <>
      {RIF(
        tabList.length > 1,
        <CorrelationDataFrameCorrelationTabList
          {...{
            tabList,
            participantInsigniaTable,
            coord,
            setCoord,
            duration,
          }}
        />,
      )}
      <ReactGridLayoutWidthProvider
        css={{
          border: `1.5px solid ${color.border._80}`,
          borderRadius: tabList.length > 1 ? '0 0 8px 8px' : '8px',
          overflow: 'hidden',
          backgroundColor: color.white,
        }}
        cols={displayingVariableList.length + 1}
        rowHeight={30}
        margin={[0, 0]}
      >
        {table.map(({dataGrid, value, type}) => (
          <div
            css={{
              border: `.5px solid ${color.border._80}`,
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: type === DataFrameTableItemType.Header ? color.surface.grey.light : color.white,
              justifyContent: type === DataFrameTableItemType.Value ? 'center' : 'flex-start',
              padding: '8px',
              color: type === DataFrameTableItemType.Value ? color.textIcon.secondary : color.textIcon.light,
              fontSize: fontSize.h7,
            }}
            key={`${dataGrid.x}-${dataGrid.y}`}
            data-grid={dataGrid}
          >
            {value === 'undefined' ? 'No Data' : value}
          </div>
        ))}
      </ReactGridLayoutWidthProvider>
    </>
  )
}

const CorrelationDataFrameCorrelationTabList = (props: {
  tabList: CorrelationDataFrameCorrelationCoord[]
  participantInsigniaTable: {[key: string]: string}
  coord: CorrelationDataFrameCorrelationCoord
  setCoord: Dispatch<SetStateAction<CorrelationDataFrameCorrelationCoord>>
  duration: [DateObject, DateObject]
}) => {
  const {setCoord, tabList, coord, participantInsigniaTable, duration} = props
  const {color, fontSize, fontWeight} = selectTheme()

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'flex-end',
      }}
    >
      {tabList.map((tab) => (
        <button
          key={v4()}
          onClick={() => {
            setCoord(tab)
          }}
          css={{
            border: `2px solid ${color.border._80}`,
            borderBottom: 'none',
            borderRadius: '8px 8px 0px 0px',
            padding: _.isEqual(tab, coord) ? '8px 24px' : '4px 24px',
            backgroundColor: _.isEqual(tab, coord) ? color.white : color.surface.grey.dark,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: fontSize.h7,
            fontWeight: fontWeight.bold,
            color: color.textIcon.light,
            cursor: 'pointer',
          }}
        >
          {`${tab.individual ? participantInsigniaTable[tab.individual] : tab.group ? tab.group : 'Individual'} (${
            tab.duration ?? `${duration[0].format('MMM D')} - ${duration[1].format('MMM D')}`
          })`}
        </button>
      ))}
    </div>
  )
}

// TODO: implement onClick
const CorrelationDataFrameDistribution = (props: {
  data: DataWithCoord<CorrelationResultDistributionData>[]
  displayName: (identifier: string) => string
  selected: CorrelationCoordinate | undefined
  onClickCell: (coord: CorrelationCoordinate) => void
}) => {
  const {data, selected, onClickCell, displayName} = props
  const {color} = selectTheme()
  const table = [
    {dataGrid: {x: 0, y: 0, w: 4, h: 1, static: true}, value: ''},
    {dataGrid: {x: 4, y: 0, w: 1, h: 1, static: true}, value: 'N'},
    {dataGrid: {x: 5, y: 0, w: 1, h: 1, static: true}, value: 'Mean'},
    {dataGrid: {x: 6, y: 0, w: 1, h: 1, static: true}, value: 'SD'},
    {dataGrid: {x: 7, y: 0, w: 1, h: 1, static: true}, value: 'SE'},
    {dataGrid: {x: 0, y: 1, w: 8, h: 1, static: true}, value: 'distribution'},
  ]
  data.forEach(({data, coord}, index) => {
    table.push({dataGrid: {x: 0, y: index + 2, w: 4, h: 1, static: true}, value: displayName(coord.column)})
    table.push({dataGrid: {x: 4, y: index + 2, w: 1, h: 1, static: true}, value: `${data.length}`})
    table.push({dataGrid: {x: 5, y: index + 2, w: 1, h: 1, static: true}, value: `${data.mean?.toFixed(2)}`})
    table.push({dataGrid: {x: 6, y: index + 2, w: 1, h: 1, static: true}, value: `${data.std?.toFixed(2)}`})
    table.push({dataGrid: {x: 7, y: index + 2, w: 1, h: 1, static: true}, value: `${data.sem?.toFixed(2)}`})
  })
  return (
    <ReactGridLayoutWidthProvider
      css={{
        border: `1.5px solid ${color.border._80}`,
        borderRadius: '5px',
        overflow: 'hidden',
        marginTop: '32px',
        backgroundColor: color.white,
      }}
      cols={8}
      rowHeight={30}
      margin={[0, 0]}
    >
      {table.map(({dataGrid, value}) => (
        <div
          css={{
            border: `.5px solid ${color.border._80}`,
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            padding: '8px',
            color: color.textIcon.secondary,
          }}
          key={`${dataGrid.x}-${dataGrid.y}`}
          data-grid={dataGrid}
        >
          {value === 'undefined' ? 'No Data' : value}
        </div>
      ))}
    </ReactGridLayoutWidthProvider>
  )
}

// TODO: implement
const CorrelationDataFramePairedSampleTTest = (props: {
  data: DataWithCoord<CorrelationResultTTestData>[]
  selected: CorrelationCoordinate | undefined
  onClickCell: (coord: CorrelationCoordinate) => void
}) => {
  return <div></div>
}

// TODO: implement
const CorrelationDataFrameTwoSampleTTest = (props: {
  data: DataWithCoord<CorrelationResultTTestData>[]
  selected: CorrelationCoordinate | undefined
  onClickCell: (coord: CorrelationCoordinate) => void
}) => {
  return <div></div>
}

// TODO: implement CorrelationResultRepeatedMeasuresCorrelationData data frame
// TODO: implement CorrelationResultTwoWayAnovaData data frame
// TODO: implement CorrelationResultPairwiseTukeyHSDData data frame

export const CorrelationDataFrameBlock = (
  props: CorrelationResult & {
    displayName: (identifier: string) => string
    selected: CorrelationCoordinate | undefined
    onClickCell: (coord: CorrelationCoordinate) => void
    displayingVariableList: Variable[]
    duration: [DateObject, DateObject]
  },
) => {
  const {items} = props
  const data = items.flatMap((row) => row.flatMap((item) => item.data.map((data) => ({data, coord: item.coord}))))
  const dataGroups = groupBy(data, ({data}) => data.analysis_type)
  return (
    <div
      css={{
        marginBottom: '32px',
      }}
    >
      {Object.entries(dataGroups).map(([analysisType, dataGroup]) => {
        switch (analysisType) {
          case CorrelationResultAnalysisType.Correlation:
            return (
              <CorrelationDataFrameCorrelation
                key={analysisType}
                data={dataGroup as DataWithCoord<CorrelationResultCorrelationData>[]}
                selected={props.selected}
                onClickCell={props.onClickCell}
                displayingVariableList={props.displayingVariableList}
                duration={props.duration}
              />
            )
          case CorrelationResultAnalysisType.Distribution:
            return (
              <CorrelationDataFrameDistribution
                key={analysisType}
                data={dataGroup as DataWithCoord<CorrelationResultDistributionData>[]}
                selected={props.selected}
                onClickCell={props.onClickCell}
                displayName={props.displayName}
              />
            )
          case CorrelationResultAnalysisType.PairedSampleTTest:
            return (
              <CorrelationDataFramePairedSampleTTest
                key={analysisType}
                data={dataGroup as DataWithCoord<CorrelationResultTTestData>[]}
                selected={props.selected}
                onClickCell={props.onClickCell}
              />
            )
          case CorrelationResultAnalysisType.TwoSampleTTest:
            return (
              <CorrelationDataFrameTwoSampleTTest
                key={analysisType}
                data={dataGroup as DataWithCoord<CorrelationResultTTestData>[]}
                selected={props.selected}
                onClickCell={props.onClickCell}
              />
            )
          default:
            throw new Error('unhandled analysis type')
        }
      })}
    </div>
  )
}
