import {v4 as uuid} from 'uuid'
import {eachDayOfInterval, format} from 'date-fns'
import {getTimezoneOffset, utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz'
import * as dateFns from 'date-fns'
import _ from 'lodash'
import {Nullish} from 'utility-types'
import { DateObject } from 'react-multi-date-picker'
import { CorrelationVariableTypeDisplayNameMap } from '../model'
import { Variable, VariableDataType, VariableType } from '../shared/analysis'

const isoTimezoneRegex = /(Z|[+-](?:2[0-3]|[01][0-9])(?::?(?:[0-5][0-9]))?)$/
const getTimezone = (isoString: string) => isoString.match(isoTimezoneRegex)?.shift()
const stripTimezone = (isoString: string) => isoString.replace(isoTimezoneRegex, '')

const utcToLocalTime = (date: Date | number | string) => {
  const utcDate = new Date(date)
  return new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000)
}

const unwrap = <T>(value: T | undefined | null, errorMessage?: string): T => {
  if (value === null || value === undefined) {
    throw new Error(errorMessage || 'Missing value')
  } else {
    return value
  }
}

const dateToYYMMDD = (date: Date): number => Number(format(date, 'yyMMdd'))

const dateFromYYMMDD = (yymmddIndex: number): Date => {
  const yymmdd = String(yymmddIndex)
    .match(/.{1,2}/g)
    ?.map(Number)
  if (!yymmdd || yymmdd.length !== 3) {
    throw new Error(`invalid yymmddIndex ${yymmddIndex}`)
  }
  const [yy, mm, dd] = yymmdd
  return new Date(Date.UTC(2000 + yy, mm - 1, dd))
}

const dateRangetoYYMMDDRange = (start: Date | string | number, end: Date | string | number): number[] =>
  eachDayOfInterval({start: new Date(start), end: new Date(end)}).map(dateToYYMMDD)

const yymmddRange = (yymmddIndexStart: number, yymmddIndexEnd: number): number[] =>
  dateRangetoYYMMDDRange(dateFromYYMMDD(yymmddIndexStart), dateFromYYMMDD(yymmddIndexEnd))

const stringToStream = (str: string): ReadableStream =>
  new ReadableStream({
    start(controller) {
      controller.enqueue(new TextEncoder().encode(str))
      controller.close()
    },
  })

const streamToString = (stream: ReadableStream): Promise<string> => {
  const reader = stream.getReader()
  const textDecoder = new TextDecoder()
  let result = String()

  async function read(): Promise<string> {
    const {done, value} = await reader.read()
    if (done) return result
    result += textDecoder.decode(value, {stream: true})
    return read()
  }

  return read()
}

const hexToRgb = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return {r, g, b}
}

const rgbToHex = (r: number, g: number, b: number): string => {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
}

const applyOpacity = (baseHex: string, opacity: number): string => {
  const baseColor = hexToRgb(baseHex)

  const blendedColor = {
    r: Math.round(baseColor.r * opacity + 255 * (1 - opacity)),
    g: Math.round(baseColor.g * opacity + 255 * (1 - opacity)),
    b: Math.round(baseColor.b * opacity + 255 * (1 - opacity)),
  }

  return rgbToHex(blendedColor.r, blendedColor.g, blendedColor.b)
}

const addHashIfNeeded = (hexString: string): string => (hexString.startsWith('#') ? hexString : '#' + hexString)

const reorder = <T>(list: Iterable<T> | ArrayLike<T>, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const toDate = (date: Date | number | string) => (_.isDate(date) ? date : new Date(date))
const formatYYMMDD = (date: Date): string => dateFns.format(date, 'yyMMdd')
const DfromYYMMDDIndex = (yymmddIndex: number | string): Date => {
  const matchResult = (_.isString(yymmddIndex) ? yymmddIndex : String(yymmddIndex)).match(/.{1,2}/g)
  const [yy, mm, dd] = unwrap(matchResult).map(Number)
  return new Date(Date.UTC(2000 + yy, mm - 1, dd))
}
const DtoYYMMDDRange = (start: Date | string | number, end: Date | string | number): string[] =>
  dateFns.eachDayOfInterval({start: toDate(start), end: toDate(end)}).map(formatYYMMDD)
const toYYMMDDRange = (start: number, end: number): number[] => {
  const startDate = DfromYYMMDDIndex(start)
  const endDate = DfromYYMMDDIndex(end)
  return DtoYYMMDDRange(startDate, endDate).map((yymmdd) => parseInt(yymmdd))
}

export const t = {
  uuid,
  log: console.log,
  utcToLocalTime,
  utcToZonedTime,
  zonedTimeToUtc,
  getTimezoneOffset,
  getTimezone,
  stripTimezone,
  unwrap,
  dateToYYMMDD,
  dateFromYYMMDD,
  dateRangetoYYMMDDRange,
  yymmddRange,
  streamToString,
  stringToStream,
  hexToRgb,
  rgbToHex,
  applyOpacity,
  addHashIfNeeded,
  reorder,
  toYYMMDDRange,
  isFiniteNumber: (value: unknown | Nullish): value is number => _.isNumber(value) && _.isFinite(value),
}
