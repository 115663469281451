import {useState, useEffect} from 'react'
import {selectTheme} from '../../store'
import {_, durationToYYMMDDInterval, getVariableDisplayName, RIF, t} from '../../lib'
import {Scrollbars} from 'react-custom-scrollbars-2'
import {
  Variable,
  VariableDataType,
  CorrelationResponse,
  CorrelationResult,
  TodoVariableType,
  TimerVariableType,
} from '../../shared/analysis'
import {CorrelationVariableTypeDisplayNameMap, DataTypeDisplayNameMap} from '../../model'
export interface AnalysisMatrixBlockProps {
  participantInsignia: string
  duration: [DateObject, DateObject]
  displayingVariableList: Variable[]
  correlationResponse?: CorrelationResponse
}
import {
  CorrelationDataFrameBlock,
  CorrelationComparisonResult,
  CorrelationMatrix,
  CorrelationCoordinate,
} from '../charts/correlation_graph'
import {DateObject} from 'react-multi-date-picker'

export const AnalysisMatrixBlock = (props: AnalysisMatrixBlockProps) => {
  const {participantInsignia, duration, displayingVariableList, correlationResponse} = props
  /* ------------------ req ------------------ */
  const {color, fontSize} = selectTheme()
  const [graphDateRange, setGraphDateRange] = useState<number[]>([0, 0])
  const [selectedCoordinate, setSelectedCoordinate] = useState<CorrelationCoordinate | undefined>()

  useEffect(() => {
    const dataDateRange = durationToYYMMDDInterval(duration)
    if (_.isEqual(dataDateRange, graphDateRange)) return
    setGraphDateRange(dataDateRange)
  }, [duration])

  const displayName = (identifier: string): string => {
    const displayingVariable = _.find(displayingVariableList, ['identifier', identifier])
    if (!displayingVariable) return 'Unknown'
    switch (displayingVariable.dataType) {
      case VariableDataType.Todo:
        switch (displayingVariable.variableType) {
          case TodoVariableType.Occurred:
            return `${displayingVariable.taskName} (${
              CorrelationVariableTypeDisplayNameMap[VariableDataType.Todo][TodoVariableType.Occurred]
            }${displayingVariable.threshold ? ` - threshold: ${displayingVariable.threshold}` : ''})`
          case TodoVariableType.Count:
            return `${displayingVariable.taskName} (${getVariableDisplayName(displayingVariable)})`
          default:
            return 'Unknown'
        }
      case VariableDataType.Timer:
        switch (displayingVariable.variableType) {
          case TimerVariableType.Occurred:
            return `${displayingVariable.taskName} (${getVariableDisplayName(displayingVariable)}${
              displayingVariable.threshold ? ` - threshold: ${displayingVariable.threshold}` : ''
            })`
          case TimerVariableType.Count:
            return `${displayingVariable.taskName} (${getVariableDisplayName(displayingVariable)})`
          case TimerVariableType.TotalDuration:
            return `${displayingVariable.taskName} (${getVariableDisplayName(displayingVariable)})`
          case TimerVariableType.AverageDuration:
            return `${displayingVariable.taskName} (${getVariableDisplayName(displayingVariable)})`
          default:
            return 'Unknown'
        }
      case VariableDataType.GarminConnectSleep:
        return `${DataTypeDisplayNameMap[VariableDataType.GarminConnectSleep]} (${getVariableDisplayName(
          displayingVariable,
        )})`
      case VariableDataType.GarminConnectActivity:
        return `${DataTypeDisplayNameMap[VariableDataType.GarminConnectActivity]} (${getVariableDisplayName(
          displayingVariable,
        )})`
      case VariableDataType.GarminConnectHeartRate:
        return `${DataTypeDisplayNameMap[VariableDataType.GarminConnectHeartRate]} (${getVariableDisplayName(
          displayingVariable,
        )})`
      case VariableDataType.GarminConnectStress:
        return `${DataTypeDisplayNameMap[VariableDataType.GarminConnectStress]} (${getVariableDisplayName(
          displayingVariable,
        )})`
      case VariableDataType.GarminDeviceHrv:
        return `${DataTypeDisplayNameMap[VariableDataType.GarminDeviceHrv]} (${getVariableDisplayName(
          displayingVariable,
        )})`
      case VariableDataType.GarminDeviceActigraphySleep:
        return `${DataTypeDisplayNameMap[VariableDataType.GarminDeviceActigraphySleep]} (${getVariableDisplayName(
          displayingVariable,
        )})`
      default:
        return 'Unknown'
    }
  }

  return (
    <Scrollbars autoHide={true} autoHideTimeout={200}>
      <div
        css={{
          flex: 1,
          backgroundColor: color.white,
          padding: '16px 32px 32px',
          borderRadius: '5px',
          marginBottom: '32px',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '24px',
            width: '100%',
          }}
        >
          <div
            css={{
              height: '16px',
              width: '16px',
              borderRadius: '50%',
              backgroundColor: color.primary,
              marginRight: '8px',
            }}
          />
          <p
            css={{
              fontSize: fontSize.h7,
            }}
          >{`${participantInsignia} (${duration[0].format('MMM D')} - ${duration[1].format('MMM D')})`}</p>
        </div>
        <div
          css={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flex: 1,
          }}
        >
          <div
            css={{
              flex: 1,
              marginRight: '16px',
            }}
          >
            {RIF(
              correlationResponse,
              <CorrelationMatrix
                {...{
                  items: correlationResponse?.result.items ?? [],
                  selected: selectedCoordinate,
                  onClickGraph: setSelectedCoordinate,
                }}
              />,
            )}
          </div>
          <CorrelationComparisonResult
            {...{
              items: correlationResponse?.result.items ?? [],
              selected: selectedCoordinate,
              onClickGraph: setSelectedCoordinate,
              displayingVariableList,
            }}
          />
        </div>
      </div>
      {RIF(
        correlationResponse,
        <CorrelationDataFrameBlock
          {...{
            items: correlationResponse?.result.items ?? [],
            displayName,
            selected: selectedCoordinate,
            onClickCell: setSelectedCoordinate,
            displayingVariableList,
            duration,
          }}
        />,
      )}
    </Scrollbars>
  )
}
